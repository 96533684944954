import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import SettingsHeader from './../components/SettingsHeader';
import Button from './../components/Buttons';
import { AppContext } from '../context/Context';
import axios from 'axios';

import scrollTo from '../utils/scrollTo';

const CleaningInstructions = ({ history }) => {
    const scrollToBottomRef = useRef(null);

    const ctx = useContext(AppContext);

    const finish = () => {
        const updatedState = {
            ...ctx.state.apistate,
            cleaning_inspection: {
                ...ctx.state.apistate.cleaning_inspection,
                areas: ctx.state.apistate.cleaning_inspection.areas.map(area => {
                    return {
                        ...area,
                        completed: true
                    };
                }),
                processed: true
            }
        };

        const options = {
            url: `${ process.env.REACT_APP_API_URL }/app/createInspectionCleaningData`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: updatedState
        };

        axios(options).then(async response => {
            ctx.setApiState(response.data);

            history.goBack();
        });
    };

    return (
        <Page>
            <SettingsHeader
                handleBack={ () => history.goBack() }
                color="white"
                header="Städinstruktioner"
            >
                <div
                    className="arrow-down"
                    onClick={ () => scrollTo(scrollToBottomRef.current) }
                >
                    <div className="circle" />
                    <i className="fal fa-arrow-down" />
                </div>
            </SettingsHeader>

            <Wrapper>
                <span style={ { width: '100%', margin: '30px 0 15px' } } />
                <strong>Generellt:</strong>
                <List>
                    <ListItem>
                        Ta bort spikar, skruvar och tavelkrokar. Återställ eventuella
                        hål.
                    </ListItem>
                    <ListItem>
                        Putsa alla fönster, både på insidan, utsidan och mellan.
                        Eventuella persienner ska också rengöras.
                    </ListItem>
                    <ListItem>
                        Rengör elementen – glöm inte städa bakom dem.
                    </ListItem>
                    <ListItem>Dammsug och våttorka alla golv.</ListItem>
                </List>
                <br />
                <strong>Det här ska du torka i alla rum:</strong>
                <List>
                    <ListItem>Tak</ListItem>
                    <ListItem>Väggar</ListItem>
                    <ListItem>Väggkontakter</ListItem>
                    <ListItem>Strömbrytare</ListItem>
                    <ListItem>Fönsterbleck</ListItem>
                    <ListItem>Fönsterkarmar</ListItem>
                    <ListItem>All inredning</ListItem>
                    <ListItem>Innerdörrar</ListItem>
                    <ListItem>Dörrhandtag</ListItem>
                </List>
                <br />
                <strong>I kök:</strong>
                <List>
                    <ListItem>
                        Rengör på, i och runt spisen. Dra ut den för att rengöra
                        bakom. Använd fettlösande medel.
                    </ListItem>
                    <ListItem>Rengör spisfläkt och kryddhyllor.</ListItem>
                    <ListItem>Frosta av frysen.</ListItem>
                    <ListItem>
                        Rengör frysen inuti, utanpå och bakom (om den går att dra ut).
                    </ListItem>
                    <ListItem>
                        Stäng av frysen. Lämna dörren på glänt för att hålla den
                        fräsch.
                    </ListItem>
                    <ListItem>
                        Rengör kylskåpet inuti, utanpå och bakom (om det går att dra
                        ut).
                    </ListItem>
                    <ListItem>Diska löstagbara lådor i kylskåpet.</ListItem>
                    <ListItem>
                        Stäng av kylskåpet när det är tömt och rengjort. Lämna dörren
                        på glänt för att undvika dålig lukt.
                    </ListItem>
                    <ListItem>
                        Rengör köksskåp och lådor från fläckar och damm, såväl inuti
                        som utanpå.
                    </ListItem>
                    <ListItem>
                        Skura diskbänken och vasken. De ska vara rena från smuts och
                        kalk.
                    </ListItem>
                    <ListItem>
                        Lampor som hör till lägenheten måste rengöras. Skruva loss
                        lampkupor och diska dem.
                    </ListItem>
                </List>
                <br />
                <strong>I badrum:</strong>
                <List>
                    <ListItem>Rensa golvbrunnen.</ListItem>
                    <ListItem>
                        Skruva isär vattenlåset under handfatet. Rengör från
                        avlagringar och smuts. Se till att det är tätt igen efteråt.
                    </ListItem>
                    <ListItem>Rengör kakel och fogar.</ListItem>
                    <ListItem>Torka av rör och vattenledningar.</ListItem>
                    <ListItem>Rengör toaletten, både i och runt om.</ListItem>
                    <ListItem>
                        Rengör handfatet. Missa inte att torka bakom.
                    </ListItem>
                    <ListItem>
                        Har du badkar? Glöm inte att städa under det. Ta loss fronten
                        om det är möjligt och skrubba bakom den.
                    </ListItem>
                </List>
                <br />
                <strong>I lägenhetsförråd och på balkongen:</strong>
                <List>
                    <ListItem>Ta bort egna golvbeläggningar.</ListItem>
                    <ListItem>Torka av golv.</ListItem>
                    <ListItem>Torka av balkongräcke.</ListItem>
                    <ListItem>Torka av hyllor.</ListItem>
                    <ListItem>Torka av fönsterbleck.</ListItem>
                </List>

                <div className="actions">
                    <p>Jag intygar att städningen i lägenheten är genomförd enligt instruktionerna ovan</p>
                    <BtnWrp ref={ scrollToBottomRef }>
                        <Button
                            onClick={ () => finish() }
                            message="Bekräfta"
                            color="#51B35B"
                            textColor="white"
                        />
                        <Button
                            onClick={ () => history.goBack() }
                            message="Tillbaka"
                            color="#E2EDF5"
                        />
                    </BtnWrp>
                </div>
            </Wrapper>
        </Page>
    );
};

export default CleaningInstructions;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    
    h1 {
        margin-bottom: 16px;
    }

    .arrow-down {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        color: white;
        font-size: 28px;
        font-weight: 100;

        .circle {
            :before {
                content: "";
                display: inline-block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: white;
            }
        }

        i {
            margin-top: 8px;
            margin-bottom: 16px;
        }
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: white;
    color: #002837;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    padding: 0 30px;
    
    .actions {
        text-align: center;
        
        margin-top: 50px;
        margin-bottom: 25px;
    }
`;

const BtnWrp = styled.div`
    width: 75%;
    margin: 16px auto 0 auto;

    button + button {
        margin-top: 8px;
    }
`;

const List = styled.ul`
    width: 100%;
    padding: 0 30px;
`;

const ListItem = styled.li`
    color: #002837;
    list-style-type: disc;
`;
