import React, { Component } from 'react';
import styled from 'styled-components';
import QuestionMarkView from '../../components/QuestionMarkView';
import Div100vh from 'react-div-100vh';
import { AppContext } from '../../context/Context';

export default class HallHelp extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <QuestionMarkView
          index={this.props.location.state.index}
          goBack={this.handleBack}
        >
          <Headline>Hall/tambur</Headline>
          <Player>
            <iframe
              title="how it works"
              src="https://player.vimeo.com/video/378976717"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </Player>
          <Text>
            Syftet med alla bilder är att visa så mycket som möjligt. Det finns
            inga krav på att det ska se ut på ett speciellt sätt. En långsmal
            hall kräver flera bilder. Det är ofta enklast att få med mycket om
            du står på mitten av en kortsida. Ta separata bilder för golv och
            tak om det behövs. Är det mörkt, tryck med fingret där det är
            ljusast innan du fotograferar. På så vis kan bilden bli ljusare.
          </Text>
        </QuestionMarkView>
      </Div100vh>
    );
  }
}

HallHelp.contextType = AppContext;

const Headline = styled.h1`
  color: rgb(0, 40, 55);
  text-align: center;
  margin-bottom: 15px;
`;

const Text = styled.p`
  color: rgb(0, 40, 55);
  padding: 0 30px;
`;

const Player = styled.div`
  position: relative;
  width: 100%;
  height: 31vh;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
