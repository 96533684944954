import React from 'react';
import styled from 'styled-components';
import MaterialIcon from './MaterialIcon';
import Compressor from 'compressorjs';
// import axios from 'axios';

export default class Camera extends React.Component {
  state = {
    image: null
  };

  handleImage(image, apiImage) {
    this.props.handleImage(image, this.props.index, apiImage);
  }

  showImageFromApi(image) {
    this.setState({
      image: image,
      imageLink: image
    });
  }

  showImage(image) {
    const cameraImage = URL.createObjectURL(image);

    this.setState({
      image: cameraImage,
      imageLink: image
    });
  }

  returnImageFromApiProp = () => {
    return this.props.imageFromApi;
  };

  handleCamera(e) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const handleImage = (image, apiImage) => {
      this.handleImage(image, apiImage);
    };

    const showImage = image => {
      this.showImage(image);
    };

    const imageFromApi = this.returnImageFromApiProp();

    new Compressor(file, {
      quality: 1,
      maxHeight: 1920,
      maxWidth: 1080,

      success(result) {
        showImage(result);

        handleImage(result, imageFromApi);
      },

      error(err) {
        console.log(err.message);
      }
    });
  }

  Thumbnail() {
    return (
      <Image
        onClick={() => this.props.showImage(`${this.state.image}`)}
        image={`${this.state.image}`}
      ></Image>
    );
  }

  backgroundColor() {
    return this.state.image !== null ? '#52b259' : null;
  }

  cameraIcon() {
    return this.state.image !== null ? 'check' : 'photo_camera';
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    if (this.props.imageFromApi) {
      this.showImageFromApi(this.props.imageFromApi.image_url);
    }
  }

  render() {
    return (
      <Wrapper style={this.props.style}>
        {this.state.image !== null ? this.Thumbnail() : null}

        <InnerWrapper>
          <CameraWrapper background={this.props.background}>
            <Border visible={this.props.visible}></Border>
            {this.props.disabled ? <Overlay /> : null}
            <CameraInput
              disabled={this.props.disabled}
              type="file"
              accept="image/*"
              capture="camera"
              title="camera"
              onChange={e => this.handleCamera(e)}
            />

            <Text>{this.props.text}</Text>

            <IconWrp
              cameraColor={this.props.cameraColor}
              style={{ backgroundColor: `${this.backgroundColor()}` }}
            >
              <MaterialIcon
                icon={this.cameraIcon()}
                size="24px"
                color="white"
              />
            </IconWrp>
          </CameraWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 30px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 1;
`;

const Image = styled.div`
  height: 60px;
  width: 45px;
  margin-right: 10px;
  background: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const CameraWrapper = styled.form`
  position: relative;
  height: 60px;
  width: 100%;
  background-color: ${props => props.background};
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;
`;

const Border = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  border: 3px dashed white;
`;

const CameraInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
`;

const Text = styled.span`
  position: absolute;
  color: #002837;
  margin-left: 20px;
  font-size: 18px;
`;

const IconWrp = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  top: -5;
  right: 0;
  background-color: ${props => props.cameraColor};
  border-radius: 0 3px 3px 0;
`;
