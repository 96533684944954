import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import AppProvider from '../context/Context';
import {Route, Switch} from 'react-router-dom';
import Room from './Room';
import WhiteGoods from './whitegoods/WhiteGoods';
import RefrigeratorInspection from './whitegoods/RefrigeratorInspection';
import StoveInspection from './whitegoods/StoveInspection';
import DishwasherInspection from './whitegoods/DishwasherInspection';
import StoveFanInspection from './whitegoods/StoveFanInspection';
import StartPage from './StartPage';
import WelcomeScreen from './WelcomeScreen';
import HowItWorks from './HowItWorks';
import UserSettings from './UserSettings';
import Language from './settings/Language';
import About from './settings/About';
import Contact from './settings/Contact';
import User from './settings/User';
import UserPolicy from './UserPolicy';
import DataProtection from './DataProtection';
import Inspection from './Inspection';
import EndPage from './EndPage';
import ReportFlaw from './ReportFlaw';
import ReportFlawRoom from './ReportFlawRoom';
import ShowFlaw from './ShowFlaw';
import ReportCleaningFlaw from './ReportCleaningFlaw';
import ReportCleaningFlawRoom from './ReportCleaningFlawRoom';
import CleaningInstructions from './CleaningInstructions';
import ShowCleaningFlaw from './ShowCleaningFlaw';
import ScrollToTop from '../components/ScrollToTop';
import StartPageHelp from './HelpViews/StartPageHelp';
import InspectionHelp from './HelpViews/InspectionHelp';
import HallHelp from './HelpViews/HallHelp';
import KitchenHelp from './HelpViews/KitchenHelp';
import BathroomHelp from './HelpViews/BathroomHelp';
import RoomHelp from './HelpViews/RoomHelp';
import StorageHelp from './HelpViews/StorageHelp';
import BalconyHelp from './HelpViews/BalconyHelp';
import KitchenInspectionHelp from './HelpViews/KitchenInspectionHelp';

export default class App extends React.Component {
    render() {
        return (
            <>
                <AppProvider>
                    <AppMain>
                        <Switch>
                            <ScrollToTop>
                                <Route
                                    exact
                                    path="/"
                                    component={WelcomeScreen}
                                />
                                <Route
                                    path="/startpage"
                                    component={StartPage}
                                />
                                <Route path="/room" component={Room} />
                                <Route
                                    path="/whitegoods"
                                    component={WhiteGoods}
                                />
                                <Route
                                    path="/refrigerator"
                                    component={RefrigeratorInspection}
                                />
                                <Route
                                    path="/stove"
                                    component={StoveInspection}
                                />
                                <Route
                                    path="/dishwasher"
                                    component={DishwasherInspection}
                                />
                                <Route
                                    path="/stove-fan"
                                    component={StoveFanInspection}
                                />
                                <Route
                                    path="/how-it-works"
                                    component={HowItWorks}
                                />
                                <Route
                                    path="/settings"
                                    component={UserSettings}
                                />
                                <Route path="/user" component={User} />
                                <Route path="/language" component={Language} />
                                <Route path="/about" component={About} />
                                <Route path="/contact" component={Contact} />
                                <Route
                                    path="/userpolicy"
                                    component={UserPolicy}
                                />
                                <Route
                                    path="/gdpr"
                                    component={DataProtection}
                                />
                                <Route
                                    path="/inspection"
                                    component={Inspection}
                                />
                                <Route
                                    path="/checklist"
                                    component={CleaningInstructions}
                                />
                                <Route path="/endpage" component={EndPage} />
                                <Route
                                    path="/report-flaw"
                                    component={ReportFlaw}
                                />
                                <Route
                                    path="/report-flaw-room"
                                    component={ReportFlawRoom}
                                />
                                <Route path="/show-flaw" component={ShowFlaw} />
                                <Route
                                    path="/report-cleaning-flaw"
                                    component={ReportCleaningFlaw}
                                />
                                <Route
                                    path="/report-cleaning-flaw-room"
                                    component={ReportCleaningFlawRoom}
                                />
                                <Route
                                    path="/show-cleaning-flaw"
                                    component={ShowCleaningFlaw}
                                />
                                <Route
                                    path="/help-startpage"
                                    component={StartPageHelp}
                                />
                                <Route
                                    path="/help-inspection"
                                    component={InspectionHelp}
                                />
                                <Route path="/help-hall" component={HallHelp} />
                                <Route
                                    path="/help-kitchen"
                                    component={KitchenHelp}
                                />
                                <Route
                                    path="/help-bathroom"
                                    component={BathroomHelp}
                                />
                                <Route path="/help-room" component={RoomHelp} />
                                <Route
                                    path="/help-storage"
                                    component={StorageHelp}
                                />
                                <Route
                                    path="/help-balcony"
                                    component={BalconyHelp}
                                />
                                <Route
                                    path="/help-kitchen-inspection"
                                    component={KitchenInspectionHelp}
                                />
                            </ScrollToTop>
                        </Switch>
                        {/*<ChooseLanguage />*/}
                    </AppMain>
                </AppProvider>
                <GlobalStyle />
            </>
        );
    }
}

const GlobalStyle = createGlobalStyle`
    * {
        -webkit-overflow-scrolling: touch;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body, #root {
        min-height: 100vh;
    }

    body {
        font-family: sofia-pro, sans-serif;
        line-height: 27px;
        background-color: #fff;
    }

    a {
        color: white;
        text-decoration: none;
    }

    a:visited {
        color: white;
    }

    .swal2-container.swal2-center {
        overflow-y: scroll !important;
    }
`;

const AppMain = styled.div`
    min-height: 100vh;
`;
