import React from 'react';
import styled from 'styled-components';
import MaterialIcon from './MaterialIcon';

export default class Camera extends React.Component {
  render() {
    return (
      <Wrapper style={this.props.style}>
        {this.props.children}
        <Text color={this.props.color}>{this.props.room}</Text>

        <Button backgroundColor={this.props.backgroundColor}>
          <MaterialIcon icon={this.props.icon} size="24px" color="white" />
        </Button>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  margin: 0 30px;
  height: 60px;
  border-radius: 3px;
  background-color: white;
  position: relative;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 18px;
  color: ${props => props.color};
  padding-left: 20px;
`;

const Button = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  top: -5;
  right: 0;
  background-color: ${props => props.backgroundColor};
  border-radius: 0 3px 3px 0;
`;
