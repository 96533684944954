import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';

import InfoSwitch from '../components/InfoSwitch';
import Div100vh from 'react-div-100vh';

export default class HowItWorks extends React.Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <Wrapper>
          <Icon
            onClick={this.handleBack}
            className="material-icons"
            style={{
              fontSize: '22px',
              position: 'absolute',
              height: '40px',
              width: '40px',
              color: '#002837'
            }}
          >
            keyboard_arrow_left
          </Icon>

          <Center>
            <Icon
              className="material-icons-outlined"
              style={{ color: '#002837', opacity: '.7' }}
            >
              info
            </Icon>
            <Text style={{ color: '#002837' }}>
              {this.context.state.lang.InfoPage.TopText}
            </Text>
          </Center>
        </Wrapper>

        <InfoSwitch handleBack={this.handleBack} />
      </Div100vh>
    );
  }
}

HowItWorks.contextType = AppContext;

const Icon = styled.i`
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
`;

const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 15px;
  background: #f6fafc;
  display: flex;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  height: 45px;
`;

const Text = styled.span`
  opacity: 0.7;
  margin-left: 7px;
`;
