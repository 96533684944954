/**
 * Smooth scroll-to inspired by:
 * http://stackoverflow.com/a/24559613/728480
 *
 * Modified by Tankbar to be able to use a HTMLElement as argument.
 */

export default function scrollTo(target, scrollDuration = 1000, scrollOffset = 30, container = undefined) {
    if(container == null) {
        container = document.scrollingElement;
    }

    //
    // Set a default for where we're scrolling to
    //
    let scrollTo = typeof(target) === 'number'
        ? target
        : 0;

    if(typeof target === 'string') {
        // Assuming this is a selector we can use to find an element
        target = document.querySelector(target);
    }

    if(target instanceof HTMLElement) {
        if(typeof target.getBoundingClientRect === 'function') {
            scrollTo = container.scrollTop + target.getBoundingClientRect().top;
        }
    }

    scrollTo = Math.max(scrollTo - scrollOffset, 0);

    // Declarations
    const cosParameter = (container.scrollTop - scrollTo) / 2;
    let scrollCount = 0;
    let oldTimestamp = window.performance.now();

    function step(newTimestamp) {
        let tsDiff = newTimestamp - oldTimestamp;

        // Performance.now() polyfill loads late so passed-in timestamp is a larger offset
        // on the first go-through than we want so I'm adjusting the difference down here.
        // Regardless, we would rather have a slightly slower animation than a big jump so a good
        // safeguard, even if we're not using the polyfill.

        if(tsDiff > 100) {
            tsDiff = 30;
        }

        scrollCount += Math.PI / (scrollDuration / tsDiff);

        // As soon as we cross over Pi, we're about where we need to be

        if(scrollCount >= Math.PI) {
            return;
        }

        container.scrollTop = Math.round(scrollTo + cosParameter + cosParameter * Math.cos(scrollCount));
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }

    window.requestAnimationFrame(step);
}

//
// Performance.now() polyfill from:
// https://gist.github.com/paulirish/5438650
//

(function() {

    if(!("performance" in window)) {
        window.performance = {};
    }

    Date.now = (Date.now || function() {  // thanks IE8
        return new Date().getTime();
    });

    if(!("now" in window.performance)) {
        let nowOffset = Date.now();

        if(performance.timing && performance.timing.navigationStart) {
            nowOffset = performance.timing.navigationStart;
        }

        window.performance.now = function now() {
            return Date.now() - nowOffset;
        };
    }

})();
