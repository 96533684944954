import React, { Component } from 'react';
import styled from 'styled-components';
import QuestionMarkView from '../../components/QuestionMarkView';
import Div100vh from 'react-div-100vh';

export default class KitchenHelp extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <QuestionMarkView
          index={this.props.location.state.index}
          goBack={this.handleBack}
        >
          <Headline>Rum</Headline>
          <Player>
            <iframe
              title="how it works"
              src="https://player.vimeo.com/video/378976221"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </Player>
          <Text>
            Ta en bild från varje hörn. Försök hålla kameran så att du får med
            lika mycket golv som tak. Om du har ett fönster med i bild och det
            är ljust ute, testa att trycka med finger på en mörk yta innan du
            tar bilden. Då bör bilden bli ljusare.
          </Text>
        </QuestionMarkView>
      </Div100vh>
    );
  }
}

const Headline = styled.h1`
  color: rgb(0, 40, 55);
  text-align: center;
  margin-bottom: 15px;
`;

const Text = styled.p`
  color: rgb(0, 40, 55);
  padding: 0 30px;
`;

const Player = styled.div`
  position: relative;
  width: 100%;
  height: 31vh;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
