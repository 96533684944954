const sv = {
  Global: {},
  ChooseLanguage: {
    ChooseLanguage: 'Välj språk'
  },
  StartPage: {
    AppName: 'Min Besiktning',
    CarouselOne: 'Lägenhetsbesiktning made easy.',
    CarouselTwo1: 'Glöm blanketter.',
    CarouselTwo2: 'Glöm väntetider.',
    CarouselTwo3: 'Glöm - no more.'
  },
  WelcomePage: {
    Hello: 'Hej',
    Text: 'Följande lägenhet väntar på en inflyttningsbesiktning.',
    Buttons: {
      Contact: 'Kontakta förvaltare',
      Start: 'Starta besiktningen'
    }
  },
  InfoPage: {
    TopText: 'Så fungerar det',
    Tab1: {
      TabTitle: 'Lägenhetsbesiktning',
      Headline: 'Lägenhetsbesiktning',
      Preamble: 'Så gör du lägenhetsbesiktningen.',
      Text:
        "Lorem ipsum dolor amet kitsch vegan art party typewriter pour-over hoodie pitchfork retro crucifix la croix ethical +1 coloring book. Activated charcoal bicycle rights disrupt, you probably haven't heard of them meggings live-edge fixie deep v coloring book jianbing trust fund post.",
      Button: 'Tillbaka'
    },
    Tab2: {
      TabTitle: 'Åtgärdsbesiktning',
      Headline: 'Åtgärdsbesiktning',
      Preamble: 'Så gör du åtgärdsbesiktningen.',
      Text:
        "Lorem ipsum dolor amet kitsch vegan art party typewriter pour-over hoodie pitchfork retro crucifix la croix ethical +1 coloring book. Activated charcoal bicycle rights disrupt, you probably haven't heard of them meggings live-edge fixie deep v coloring book jianbing trust fund post.",
      Button: 'Tillbaka'
    }
  },
  Settings: {
    LoggedIn: 'Inloggad som',
    UserInfo: 'Användaruppgifter',
    UserInfoPage: {},
    Language: 'Språk',
    LanguagePage: {
      UpdateButton: 'Uppdatera'
    },
    About: 'Om appen',
    AboutPage: {
      Headline: 'En talande rubrik',
      Text:
        "Lorem ipsum dolor amet kitsch vegan art party typewriter pour-over hoodie pitchfork retro crucifix Ia croix ethical +1 coloring book. Activated charcoal bicycle rights disrupt, you probably haven't heard of them meggings live-edge fixie deep v coloring book jianbing trust fund post- ironic. a-bit food truck crucifix dreamcatcher squid, blue bottle gochujang cray. Dreamcatcher polaroid blog post-ironic snackwave raw denim try- hard pickled lumbersexual jianbing biodiesel humblebrag. Raclette woke kitsch brooklyn, helvetica health goth 3 wolf moon schlitz keffiyeh keytar DIY tote bag chartreuse yr. Occupy vice meditation blue bottle, chia stumptown franzen green juice distillery cold-pressed vinyl hammock sriracha vexillologist Iomo."
    },
    Contact: 'Kontakta förvaltare',
    ContactPage: {
      Preamble1: 'Har du frågor eller funderingar?',
      Preamble2: 'Kontakta din fastighetsförvaltare.',
      PropertyManager: 'Fastighetsförvaltare'
    },
    LogOut: 'Logga ut'
  }
};

export default sv;
