import React, {useEffect} from 'react';
import styled from 'styled-components';
import {AppContext} from '../context/Context';

import Div100vh from 'react-div-100vh';
import Buttons from '../components/TopButtons';
import Button from '../components/Buttons';
import {Link} from 'react-router-dom';
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Video from '../components/Video';
import MaterialIcon from '../components/MaterialIcon';

export default class WelcomeScreen extends React.Component {
    state = {
        fetched: false,
        showMessage: false,
        render: false,
        userTerms: false,
    };

    constructor(props) {
        super(props);

        this.hasSeenTerms = this.hasSeenTerms.bind(this);
    }

    hasSeenTerms() {
        this.setState({userTerms: true});
    }

    parseURL(url) {
        if (!url) {
            url = window.location.href;
        }

        if (typeof url !== 'string' || url.length === 0) {
            return {id: '', hash: ''};
        }

        const params = new URL(url).searchParams;
        return {
            id: params.get('id'),
            hash: params.get('hash'),
        };
    }

    UNSAFE_componentWillMount() {
        const URL = `${process.env.REACT_APP_API_URL}/app/getInspectionData`;

        if (!this.context.state.fetched) {
            const params = this.parseURL();

            fetch(URL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            })
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error('error');
                    }
                    response.json().then(async data => {
                        let newData = JSON.parse(JSON.stringify(data));

                        newData.inspection_data.forEach(room => {
                            let newDataImages = [];

                            room.images.forEach(image => {
                                if (image.status === 0) {
                                    newDataImages.push(image);
                                }
                            });

                            room.images = newDataImages;
                        });

                        if (
                            newData.tenant_completion_at != null &&
                            newData.tenant_completion_at.length > 0
                        ) {
                            this.setState({
                                userTerms: true,
                            });
                        }

                        await this.context.setApiState(newData);
                    });
                    this.context.setFetched();
                })
                .then(() => {
                    this.setState({
                        render: true,
                        showMessage: true,
                    });
                })
                .catch(() => {
                    this.props.history.push('/startpage');
                });
        }
    }

    toggleLangOnMount() {
        if (!sessionStorage.getItem('selectedlanguage')) {
            this.context.toggleLang();
        }
    }

    componentDidMount() {
        const params = this.parseURL();
        sessionStorage.setItem('params', JSON.stringify(params));

        setTimeout(() => {
            this.toggleLangOnMount();
        }, 600);

        if (this.context.state.fetched) {
            this.setState({
                render: true,
            });
        }
    }

    showMessage() {
        if (
            this.context.state.apistate.status_inspection !== null &&
            this.context.state.apistate.status_completion === 0
        ) {
            if (this.context.state.apistate.status_inspection !== 0) {
                let title = '';
                switch (this.context.state.apistate.status_inspection) {
                    case 1:
                        title = 'Din besiktning är godkänd';
                        break;
                    case 2:
                        title = 'Ett eller flera rum måste göras om';
                        break;
                    case 3:
                        title = 'Hela besiktningen måste göras om';
                        break;
                    default:
                        title = '';
                        break;
                }

                return (
                    <SweetAlert
                        style={{fontWeight: '500'}}
                        show={this.state.showMessage}
                        title={title}
                        text={this.context.state.apistate.comment_inspection}
                        onConfirm={() => this.setState({showMessage: false})}
                    />
                );
            }
        }

        if (
            this.context.state.apistate.status_completion === 1 ||
            this.context.state.apistate.status_completion === 2 ||
            this.context.state.apistate.status_completion === 3 ||
            this.context.state.apistate.status_completion === 4
        ) {
            let title = '';
            switch (this.context.state.apistate.status_completion) {
                case 1:
                    switch (this.context.state.apistate.inspection_type) {
                        case 'measure':
                            title = 'Din åtgärd är godkänd';
                            break;
                        case 'in':
                            title =
                                'Din besiktning är färdig. Hyresvärden har med dina bilder som underlag gjort bedömningen att ingenting behöver åtgärdas. Nu inväntas inflyttande hyresgästs besiktning att jämföras med din.';
                            break;
                        default:
                            title = 'Din besiktning är godkänd';
                    }
                    break;
                case 2:
                    switch (this.context.state.apistate.inspection_type) {
                        case 'measure':
                            title = 'Din åtgärd är ej godkänd';
                            break;
                        case 'in':
                            title =
                                'Din besiktning är färdig och din hyresvärd har gjort bedömning att ett eller flera rum behöver åtgärdas i din lägenhet. Din hyresvärd kommer att kontakta dig med mer information.';
                            break;
                        default:
                            title = 'Din besiktning är ej godkänd';
                            break;
                    }
                    break;
                case 3:
                    switch (this.context.state.apistate.inspection_type) {
                        case 'measure':
                            title = 'Dags att ta nya bilder efter din åtgärd';
                            break;
                        default:
                            title = 'Dags att ta nya bilder efter din åtgärd';
                            break;
                    }
                    break;
                case 4:
                    switch (this.context.state.apistate.inspection_type) {
                        case 'measure':
                            title = 'Din åtgärd är godkänd';
                            break;
                        default:
                            title = 'Din åtgärd är godkänd';
                            break;
                    }
                    break;
                default:
                    title = '';
                    break;
            }

            return (
                <SweetAlert
                    style={{fontWeight: '500'}}
                    show={this.state.showMessage}
                    title={title}
                    text={
                        this.context.state.apistate.status_completion !== 3
                            ? this.context.state.apistate.comment_completion
                            : this.context.state.apistate.comment_inspection
                    }
                    onConfirm={() => this.setState({showMessage: false})}
                />
            );
        }
    }

    render() {
        return (
            <Div100vh>
                <Video />
                <Overlay />
                <Gradient />
                <Wrapper>
                    {this.state.render ? (
                        <div className="fadein">
                            <Buttons helplink="/help-startpage" />
                            <InnerWrapper>
                                {this.context.state.fetched &&
                                    !this.state.userTerms &&
                                    this.context.state.apistate.status_inspection ===
                                    0 && (
                                        <UserAgreementPopup onDismiss={this.hasSeenTerms} />
                                    )}
                                {this.showMessage()}
                                <TextWrapper>
                                    <Headline>
                                        {this.context.state.lang.WelcomePage.Hello +
                                            ' ' +
                                            this.context.state.apistate.tenant_firstname}
                                    </Headline>
                                    <Text>
                                        {this.context.state.apistate.status_completion ===
                                        0
                                            ? this.context.state.apistate.inspection_type ===
                                            'in'
                                                ? 'Följande lägenhet väntar på besiktning'
                                                : this.context.state.apistate.inspection_type ===
                                                'out'
                                                    ? 'Följande lägenhet väntar på besiktning'
                                                    : this.context.state.apistate.inspection_type ===
                                                    'measure'
                                                        ? 'Följande lägenhet väntar på besiktning'
                                                        : null
                                            : null}
                                    </Text>
                                </TextWrapper>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MaterialIcon
                                            icon="location_city"
                                            size="54px"
                                            color="#e2edf5"
                                            style={{
                                                position: 'relative',
                                                top: '8px',
                                            }}
                                        />
                                    </div>

                                    <ApartmentWrapper style={{color: '#002837'}}>
                                        <p style={{fontWeight: '600'}}>
                                            Lgh{' '}
                                            {
                                                this.context.state.apistate
                                                    .rental_object_appartment_number
                                            }
                                        </p>
                                        <h2>{this.context.state.apistate.rental_object_address}</h2>
                                        <p
                                            style={{
                                                fontWeight: '600',
                                                marginTop: '5px',
                                            }}
                                        >
                                            {this.context.state.apistate.rental_object_zipcode +
                                                ' ' +
                                                this.context.state.apistate.rental_object_city}
                                        </p>
                                    </ApartmentWrapper>

                                    <BottomTriangle />
                                </div>

                                <div style={{width: '100%'}}>
                                    <Link to="/inspection">
                                        <Button
                                            disabled={
                                                (this.context.state.apistate.tenant_completion &&
                                                    this.context.state.apistate.status_inspection !==
                                                    2) ||
                                                !this.state.userTerms
                                            }
                                            message={
                                                this.context.state.lang.WelcomePage.Buttons.Start
                                            }
                                            color="white"
                                        />
                                    </Link>
                                </div>
                            </InnerWrapper>
                        </div>
                    ) : null}
                </Wrapper>
            </Div100vh>
        );
    }
}

const UserAgreementPopup = ({onDismiss}) => {
    useEffect(() => {
        const onShowPopup = () => {
            const swal = withReactContent(Swal);

            swal.fire({
                title: <p>Villkor för användning</p>,
                html: (
                    <div style={{textAlign: 'left'}}>
                        Välkommen till AB Effektforms tjänst Min
                        Besiktning. Via denna tjänst kan du som använder
                        den redovisa egen besiktning av lägenhet inför
                        inflytt/avflytt/åtgärd i enlighet med din
                        hyresvärds instruktioner.
                        <br />
                        <br />
                        Genom att använda Min Besiktning samtycker du till
                        följande villkor för din åtkomst till och
                        användning av sajten och innehållet som
                        tillhandahålls på eller via webbplatsen
                        ("Användarvillkor"). AB Effektform kan ändra
                        Användarvillkoren när som helst, varvid de
                        reviderade Användarvillkoren kommer att gälla när
                        de publiceras på{' '}
                        <a
                            href="https://www.minbesiktning.se"
                            style={{
                                color: '#002837',
                                textDecoration: 'underline',
                            }}
                        >
                            www.minbesiktning.se.
                        </a>
                        <br />
                        <br />
                        <span style={{textDecoration: 'underline'}}>
                        Personuppgifter
                    </span>
                        <br />
                        <br />
                        AB Effektform anlitas av din hyresvärd för att
                        tillhandahålla Min Besiktning och AB Effektform
                        behandlar dina personuppgifter på uppdrag av
                        hyresvärden. För att säkerställa att dina
                        personuppgifter skyddas har AB Effektform tecknat
                        ett personuppgiftsbiträdesavtal med din hyresvärd.
                        Din hyresvärd är personuppgiftsansvarig för dina
                        personuppgifter. För information om hur din
                        hyresvärd behandlar dina personuppgifter, vänligen
                        kontakta din hyresvärd eller besök din hyresvärds
                        webbsida för information om
                        personuppgiftshantering.
                        <br />
                        <br />
                        <span style={{textDecoration: 'underline'}}>
                        Programvara och webbsida
                    </span>
                        <br />
                        <br />
                        AB Effektform innehar och behåller alla
                        immateriella rättigheter till webbsidan och dess
                        innehåll, såsom programvara och kod, utseende och
                        annat upphovsrättsligt skyddat. Du äger rätt att
                        använda Min Besiktning, men inga rättigheter till
                        densamma övergår på dig genom detta nyttjande. Min
                        Besiktning får endast användas om du är hyresgäst
                        (oavsett om du är en fysisk eller juridisk person)
                        i syfte att tillhandahålla av din hyresvärd
                        efterfrågad information (nedan ”Informationen”).
                        Du äger inte rätt att kopiera, modifiera eller
                        ändra innehållet på webbsidan på något sätt,
                        utöver att tillföra Informationen.
                        <br />
                        <br />
                        <span style={{textDecoration: 'underline'}}>
                        Uppladdning av Information
                    </span>
                        <br />
                        <br />
                        Du kan ladda upp Information, i form av text
                        och/eller bilder, samt skriva ut den. Du har inte
                        tillgång till någon annans information än din
                        egen. Det är din hyresvärd som avgör vilken
                        information som efterfrågas. Vid eventuella frågor
                        kring det, vänligen kontakta hyresvärden.
                        <br />
                        <br />
                        I den omfattning av dig uppladdad
                        Information utgör immaterialrättsligt skyddat
                        material (som foton t. ex.) ger du AB Effektform
                        rätt att lagra information under den tid som din
                        hyresvärd begär, samt att fritt dela denna
                        upphovsrättsligt skyddade Information med din
                        Hyresvärd.
                        <br />
                        <br />
                        Genom att använda Min Besiktning intygar du att du
                        bara kommer att ladda upp och publicera eller på
                        annat sätt göra tillgängligt Information som du
                        har rätt och behörighet att dela. Du intygar
                        vidare att du har rätt och behörighet att bevilja
                        AB Effektform de rättigheter avseende din
                        Information som anges ovan.
                        <br />
                        <br />
                        Genom att använda Min Besiktning samtycker du till
                        att till att du varken kommer att använda
                        webbplatsen på ett sätt eller dela något innehåll
                        som är i strid med gällande lagstiftning. Inte
                        heller får du använda webbplatsen på ett sätt som
                        kan kränka någon annans personliga integritet,
                        eller som kan uppfattas som stötande på annat
                        sätt. Du äger inte heller rätt att ladda upp
                        information som kan skada Min Besiktning, såsom
                        trojaner, virus eller annan skadlig kod. Du
                        samtycker till att inte använda webbplatsen, eller
                        innehåll som tillhandahålls på eller via
                        webbplatsen, för något syfte som är olagligt eller
                        förbjudet enligt dessa Användarvillkor eller i
                        strid med gällande lag.
                        <br />
                        <br />
                        AB Effektform förbehåller sig rätten att radera
                        Information som AB Effektform kan anta är i strid
                        med de villkor som följer av dessa
                        Användarvillkor. AB Effektform kan vidare komma
                        att göra polisanmälan och/eller rapportera till
                        din hyresvärd.
                        <br />
                        <br />
                        För det fall du anser något av Informationen du
                        avser att ladda upp vara konfidentiell, så ombeds
                        du att istället vända dig direkt till din
                        Hyresvärd. AB Effektform tar inget ansvar för
                        konfidentiell information som laddas upp i Min
                        Besiktning, utöver i den omfattning som följer av
                        eventuellt tillämplig personuppgiftslagstiftning.
                        <br />
                        <br />
                        <span style={{textDecoration: 'underline'}}>
                        Säkerhet och lösenord
                    </span>
                        <br />
                        <br />
                        Du får inte försöka komma åt eller använda
                        webbplatsen på något sätt som kan skada,
                        inaktivera, överbelasta eller försämra Min
                        Besiktning.
                        <br />
                        <br />
                        <span style={{textDecoration: 'underline'}}>
                        Kontakt
                    </span>
                        <br />
                        <br />
                        Om du har några frågor rörande Användarvillkoren,
                        vänligen kontakta{' '}
                        <a
                            href="mailto:anvandarvillkor@minbesiktning.se"
                            onClick={() => null}
                            style={{
                                color: '#002837',
                                textDecoration: 'underline',
                            }}
                        >
                            anvandarvillkor@minbesiktning.se.
                        </a>
                    </div>
                ),
                showCancelButton: true,
                confirmButtonText: 'Jag godkänner',
                cancelButtonText: 'Jag godkänner ej',
            }).then(result => result.value && onDismiss());
        };

        setTimeout(() => onShowPopup(), 300);
    }, [onDismiss]);

    return null;
};

WelcomeScreen.contextType = AppContext;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    .fadein {
        animation: FadeIn 0.4s ease;
    }

    @keyframes FadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
`;

const Overlay = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    background-color: #002837;
    opacity: 0.5;
    z-index: -1;
`;

const Gradient = styled.div`
    background: linear-gradient(180deg, rgba(0, 40, 55, 0) 0%, #002837 100%);
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    color: white;
    z-index: -1;
`;

const InnerWrapper = styled.div`
    padding: 0 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const TextWrapper = styled.div``;

const ApartmentWrapper = styled.div`
    background: #e2edf5;
    padding: 25px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 272.5px;
`;

const BottomTriangle = styled.div`
    width: 100%;
    height: 55px;
    background: #e2edf5;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 10%, 50% 100%, 0 10%);
    clip-path: polygon(0 0, 100% 0, 100% 10%, 50% 100%, 0 10%);
    position: relative;
    top: -5px;
`;

const Headline = styled.h1`
    font-size: 32px;
    font-weight: 600;
    height: 45px;
    line-height: 1;
`;

const Text = styled.p`
    font-size: 18px;
    margin-bottom: 15px;
`;
