import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import TopButtons from '../../components/TopButtonsApartment';
import { Link } from 'react-router-dom';
import MaterialIcon from '../../components/MaterialIcon';
import RoomList from '../../components/RoomList';
import Buttons from '../../components/Buttons';
import axios from 'axios';
import Div100vh from 'react-div-100vh';

export default class WhiteGoods extends React.Component {
  state = {
    images: [1, 2, 3, 4]
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleSave = () => {
    this.context.reportFlawSaved(true);
    const inspection = JSON.stringify(this.context.state.apistate);

    const options = {
      url: `${process.env.REACT_APP_API_URL}/app/createInspectionKitchenData`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: inspection
    };

    axios(options).then(async response => {
      this.context.setApiState(response.data);
      setTimeout(() => {
        this.handleBack();
      }, 100);
    });
  };

  render() {
    return (
      <Div100vh>
        <Wrapper>
          <TopButtons
            helplink="/help-kitchen-inspection"
            backgroundColor="#002837"
            handleBack={() => this.handleBack()}
          >
            <div style={{ display: 'flex' }}>
              <MaterialIcon icon="location_city" size="28px" color="#e2edf5" />
              <span style={{ marginLeft: '15px' }}>
                {`Lgh ${this.context.state.apistate.rental_object_appartment_number}`}{' '}
                {this.context.state.apistate.rental_object_address}
              </span>
            </div>
          </TopButtons>

          <div style={{ width: '100%', minHeight: 'calc(100vh - 220px)' }}>
            <Headline>
              <h1>Kontrollera vitvaror</h1>
            </Headline>

            <Link
              to={{
                pathname: '/refrigerator',
                state: {
                  room: 'Kyl/Frys'
                }
              }}
              style={{ width: '100%' }}
            >
              <RoomList
                room="Kyl/Frys"
                icon={
                  this.context.state.apistate.kitchen_inspection.areas[0]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[0]
                    .is_checked === true
                    ? 'check'
                    : 'keyboard_arrow_right'
                }
                backgroundColor={
                  this.context.state.apistate.kitchen_inspection.areas[0]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[0]
                    .is_checked === true
                    ? '#52b259'
                    : '#186096'
                }
                color="#002837"
              />
            </Link>

            <Link
              to={{
                pathname: '/stove',
                state: {
                  room: 'Spis/Häll/Ugn'
                }
              }}
              style={{ width: '100%' }}
            >
              <RoomList
                room="Spis/Häll/Ugn"
                icon={
                  this.context.state.apistate.kitchen_inspection.areas[1]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[1]
                    .is_checked === true
                    ? 'check'
                    : 'keyboard_arrow_right'
                }
                backgroundColor={
                  this.context.state.apistate.kitchen_inspection.areas[1]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[1]
                    .is_checked === true
                    ? '#52b259'
                    : '#186096'
                }
                color="#002837"
              />
            </Link>

            <Link
              to={{
                pathname: '/dishwasher',
                state: {
                  room: 'Diskmaskin'
                }
              }}
              style={{ width: '100%' }}
            >
              <RoomList
                room="Diskmaskin"
                icon={
                  this.context.state.apistate.kitchen_inspection.areas[2]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[2]
                    .is_checked === true
                    ? 'check'
                    : 'keyboard_arrow_right'
                }
                backgroundColor={
                  this.context.state.apistate.kitchen_inspection.areas[2]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[2]
                    .is_checked === true
                    ? '#52b259'
                    : '#186096'
                }
                color="#002837"
              />
            </Link>

            <Link
              to={{
                pathname: '/stove-fan',
                state: {
                  room: 'Spisfläkt'
                }
              }}
              style={{ width: '100%' }}
            >
              <RoomList
                room="Spisfläkt"
                icon={
                  this.context.state.apistate.kitchen_inspection.areas[3]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[3]
                    .is_checked === true
                    ? 'check'
                    : 'keyboard_arrow_right'
                }
                backgroundColor={
                  this.context.state.apistate.kitchen_inspection.areas[3]
                    .values[0].status !== '' ||
                  this.context.state.apistate.kitchen_inspection.areas[3]
                    .is_checked === true
                    ? '#52b259'
                    : '#186096'
                }
                color="#002837"
              />
            </Link>
          </div>

          <div
            onClick={() => this.handleSave()}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '40px'
            }}
          >
            <Buttons
              color="#52b259"
              textColor="#fff"
              message="Spara och stäng"
              style={{ width: 'calc(100% - 60px)' }}
            />
          </div>
        </Wrapper>
      </Div100vh>
    );
  }
}

WhiteGoods.contextType = AppContext;

const Wrapper = styled.div`
  background: #e2edf5;
  min-width: 100%;
  min-height: 100%;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;

const Headline = styled.div`
  padding: 36px 0 0px;
  margin-bottom: 36px;
  width: 100%;
  color: #002837;

  h1 {
    font-size: 28px;
    word-wrap: nowrap;
    text-align: center;
  }
`;
