import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './assets/fonts/fontawesome-all.min.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: "https://e0a544ab95a14b5f9417f4369e134d80@sentry.tankbar.com/44",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.3 : 1,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <BrowserRouter to="/">
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
