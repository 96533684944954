import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import TopButtons from '../../components/TopButtonsApartment';
import MaterialIcon from '../../components/MaterialIcon';
import Buttons from '../../components/Buttons';
import Div100vh from 'react-div-100vh';
import axios from 'axios';

export default class RefrigeratorInspection extends React.Component {
  state = {
    openTextArea: false,
    openTextArea2: false,
    is_checked: false,
    is_completed: true,
    room: {
      id: this.props.location.state.room,
      exists: true,
      values: [
        {
          headline: this.context.state.apistate.kitchen_inspection.areas[0]
            .values[0].headline,
          status: null,
          comment: ''
        },
        {
          headline: this.context.state.apistate.kitchen_inspection.areas[0]
            .values[1].headline,
          status: null,
          comment: ''
        }
      ]
    },
    checked1: false,
    checked2: false,
    checkedfault1: false,
    checkedfault2: false
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleSave = async (room, state) => {
    await this.context.kitchenInspection(room, state);
    const inspection = JSON.parse(JSON.stringify(this.context.state.apistate));

    inspection.kitchen_inspection.areas[0].is_completed = true;

    const options = {
      url: `${process.env.REACT_APP_API_URL}/app/createInspectionKitchenData`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: inspection
    };

    axios(options).then(async response => {
      this.context.setApiState(response.data);
      setTimeout(() => {
        this.handleBack();
      }, 100);
    });
  };

  handleSaveNoBack = async (room, state) => {
    await this.context.kitchenInspection(room, state);
    const inspection = JSON.parse(JSON.stringify(this.context.state.apistate));

    const options = {
      url: `${process.env.REACT_APP_API_URL}/app/createInspectionKitchenData`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: inspection
    };

    axios(options).then(async response => {
      this.context.setApiState(response.data);
    });
  };

  handleRadio = (e, value) => {
    e.preventDefault();
    let room = { ...this.state.room };

    switch (value) {
      case 'yes':
        room.values[0].status = '1';
        this.setState({
          room: room,
          openTextArea: false,
          checked1: true,
          checkedfault1: false
        });
        break;
      case 'no':
        room.values[0].status = 'fault';
        this.setState({
          room: room,
          openTextArea: true,
          checkedfault1: true,
          checked1: false
        });
        break;
      case 'yes2':
        room.values[1].status = '1';
        this.setState({
          room: room,
          openTextArea2: false,
          checked2: true,
          checkedfault2: false
        });
        break;
      case 'no2':
        room.values[1].status = 'fault';
        this.setState({
          room: room,
          openTextArea2: true,
          checkedfault2: true,
          checked2: false
        });
        break;
      default:
        break;
    }
  };

  handleRadioNo = i => {
    let room = { ...this.state.room };

    switch (i) {
      case 'no':
        room.values[0].status = 'fault';
        this.setState({
          openTextArea: true,
          room: room,
          checked1: false,
          checked2: this.state.checked2,
          checkedfault1: true,
          checkedfault2: this.state.checkedfault2
        });
        break;
      case 'no2':
        room.values[1].status = 'fault';
        this.setState({
          openTextArea2: true,
          room: room,
          checked1: this.state.checked1,
          checked2: false,
          checkedfault1: this.state.checkedfault1,
          checkedfault2: true
        });
        break;
      default:
        break;
    }
  };

  handleChange = (index, e) => {
    let room = { ...this.state.room };
    room.values[index].comment = e.target.value;
    this.setState({
      room: room
    });
  };

  componentDidMount() {
    let room = { ...this.context.state.apistate.kitchen_inspection.areas[0] };
    room.exists = true;

    this.setState({
      room: room
    });

    if (
      this.context.state.apistate.kitchen_inspection.areas[0].values[0]
        .status === '1'
    ) {
      this.setState({ checked1: true });
    }

    if (
      this.context.state.apistate.kitchen_inspection.areas[0].values[1]
        .status === '1'
    ) {
      this.setState({ checked2: true });
    }

    if (
      this.context.state.apistate.kitchen_inspection.areas[0].values[0]
        .status === 'fault'
    ) {
      this.setState({ checkedfault1: true, openTextArea: true });
    }

    if (
      this.context.state.apistate.kitchen_inspection.areas[0].values[1]
        .status === 'fault'
    ) {
      this.setState({ checkedfault2: true, openTextArea2: true });
    }
  }

  isDisabled() {
    if (
      (this.state.checked1 && this.state.checked2) ||
      (this.state.checked1 &&
        (this.state.checkedfault2 &&
          this.state.room.values[1].comment !== '')) ||
      (this.state.checked2 &&
        (this.state.checkedfault1 &&
          this.state.room.values[0].comment !== '')) ||
      (this.state.checkedfault1 &&
        this.state.room.values[0].comment !== '' &&
        (this.state.checkedfault2 && this.state.room.values[1].comment !== ''))
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <Div100vh>
        <Wrapper>
          <TopButtons
            handleSaveNoBack={() => this.handleSaveNoBack(0, this.state.room)}
            helplink="/help-kitchen-inspection"
            backgroundColor="#002837"
            handleBack={() => this.handleBack()}
          >
            <div style={{ display: 'flex' }}>
              <MaterialIcon icon="location_city" size="28px" color="#e2edf5" />
              <span style={{ marginLeft: '15px' }}>
                {`Lgh ${this.context.state.apistate.rental_object_appartment_number}`}{' '}
                {this.context.state.apistate.rental_object_address}
              </span>
            </div>
          </TopButtons>

          <div style={{ width: '100%', minHeight: 'calc(100vh - 220px)' }}>
            <Headline>
              <h1>Kyl/Frys</h1>
            </Headline>

            <div style={{ width: '100%', padding: '0 30px' }}>
              <Text>
                {
                  this.context.state.apistate.kitchen_inspection.areas[0]
                    .values[0].headline
                }
              </Text>

              <RadioWrapper>
                <Container>
                  <input
                    checked={this.state.checked1}
                    className="yes"
                    type="checkbox"
                    id="yes"
                    name="radio1"
                  />
                  <label
                    onClick={e => this.handleRadio(e, 'yes')}
                    style={{
                      marginRight: '10px',
                      backgroundColor: `${
                        this.state.checked1 ? '#52b259' : '#fff'
                      }`,
                      color: `${this.state.checked1 ? '#fff' : '#002837'}`
                    }}
                    htmlFor="yes"
                  >
                    Ja
                  </label>

                  <input
                    checked={this.state.checkedfault1}
                    className="no"
                    type="checkbox"
                    id="no"
                    name="radio1"
                  />
                  <label
                    onClick={e => this.handleRadio(e, 'no')}
                    htmlFor="no"
                    style={{
                      backgroundColor: `${
                        this.state.checkedfault1 ? '#d46369' : '#fff'
                      }`,
                      color: `${this.state.checkedfault1 ? '#fff' : '#002837'}`
                    }}
                  >
                    Nej
                  </label>
                </Container>
              </RadioWrapper>

              {this.state.openTextArea ? (
                <TextArea
                  placeholder="Du måste skriva en kommentar."
                  onChange={e => this.handleChange(0, e)}
                  value={this.state.room.values[0].comment}
                />
              ) : null}

              <Divider />

              <Text>
                {
                  this.context.state.apistate.kitchen_inspection.areas[0]
                    .values[1].headline
                }
              </Text>

              <RadioWrapper>
                <Container>
                  <input
                    checked={this.state.checked2}
                    className="yes"
                    type="checkbox"
                    id="yes1"
                    name="radio2"
                  />
                  <label
                    onClick={e => this.handleRadio(e, 'yes2')}
                    style={{
                      marginRight: '10px',
                      backgroundColor: `${
                        this.state.checked2 ? '#52b259' : '#fff'
                      }`,
                      color: `${this.state.checked2 ? '#fff' : '#002837'}`
                    }}
                    htmlFor="yes1"
                  >
                    Ja
                  </label>

                  <input
                    checked={this.state.checkedfault2}
                    className="no"
                    type="checkbox"
                    id="no1"
                    name="radio2"
                  />
                  <label
                    onClick={e => this.handleRadio(e, 'no2')}
                    htmlFor="no1"
                    style={{
                      backgroundColor: `${
                        this.state.checkedfault2 ? '#d46369' : '#fff'
                      }`,
                      color: `${this.state.checkedfault2 ? '#fff' : '#002837'}`
                    }}
                  >
                    Nej
                  </label>
                </Container>
              </RadioWrapper>

              {this.state.openTextArea2 ? (
                <TextArea
                  placeholder="Du måste skriva en kommentar."
                  onChange={e => this.handleChange(1, e)}
                  value={this.state.room.values[1].comment}
                />
              ) : null}

              <Divider />
            </div>
          </div>

          <div
            onClick={() => this.handleSave(0, this.state.room)}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '40px'
            }}
          >
            <Buttons
              disabled={this.isDisabled()}
              color="#52b259"
              textColor="#fff"
              message="Spara och stäng"
              style={{ width: 'calc(100% - 60px)' }}
            />
          </div>
        </Wrapper>
      </Div100vh>
    );
  }
}

RefrigeratorInspection.contextType = AppContext;

const Wrapper = styled.div`
  background: #e2edf5;
  min-width: 100%;
  min-height: 100%;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;

const Headline = styled.div`
  padding: 36px 0 0px;
  margin-bottom: 36px;
  width: 100%;
  color: #002837;

  h1 {
    font-size: 28px;
    word-wrap: nowrap;
    text-align: center;
  }
`;

const Text = styled.p`
  color: #002837;
  text-align: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 20px;
  background-color: #002837;
  opacity: 0.3;
`;

const RadioWrapper = styled.div`
  margin: 30px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;

  input[type='checkbox'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: inline-block;
    background-color: #fff;
    width: 110px;
    height: 40px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #002837;
  }
`;

const TextArea = styled.textarea`
  height: 137px;
  width: 100%;
  resize: none;
  border: 0;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 20px;
  color: #636363;
`;
