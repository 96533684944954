import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import TopButtons from '../../components/TopButtonsApartment';
import MaterialIcon from '../../components/MaterialIcon';
import Buttons from '../../components/Buttons';
import Div100vh from 'react-div-100vh';
import axios from 'axios';

export default class StoveInspection extends React.Component {
  state = {
    openTextArea: false,
    openTextArea2: false,
    room: {
      id: this.props.location.state.room,
      exists: true,
      is_checked: false,
      is_completed: true,
      values: [
        {
          headline: this.context.state.apistate.kitchen_inspection.areas[2]
            .values[0].headline,
          status: null,
          comment: ''
        }
      ]
    },
    checked1: false,
    checkedfault1: false
  };

  handleCheckBox = () => {
    const room = { ...this.state.room };
    room.exists = !this.state.room.exists;
    room.is_checked = !this.state.room.is_checked;

    this.setState({
      room: room
    });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleSave = async (room, state) => {
    await this.context.kitchenInspection(room, state);
    const inspection = JSON.parse(JSON.stringify(this.context.state.apistate));

    inspection.kitchen_inspection.areas[2].is_completed = true;

    const options = {
      url: `${process.env.REACT_APP_API_URL}/app/createInspectionKitchenData`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: inspection
    };

    axios(options).then(async response => {
      this.context.setApiState(response.data);
      setTimeout(() => {
        this.handleBack();
      }, 100);
    });
  };

  handleSaveNoBack = async (room, state) => {
    await this.context.kitchenInspection(room, state);
    const inspection = JSON.parse(JSON.stringify(this.context.state.apistate));

    const options = {
      url: `${process.env.REACT_APP_API_URL}/app/createInspectionKitchenData`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: inspection
    };

    axios(options).then(async response => {
      this.context.setApiState(response.data);
    });
  };

  handleRadioNo = i => {
    let room = { ...this.state.room };

    switch (i) {
      case 'no':
        room.values[0].status = 'fault';
        this.setState({
          openTextArea: true,
          room: room,
          checked1: false,
          checked2: this.state.checked2,
          checkedfault1: true,
          checkedfault2: this.state.checkedfault2
        });
        break;
      default:
        break;
    }
  };

  handleRadio = (e, value) => {
    e.preventDefault();
    let room = { ...this.state.room };

    switch (value) {
      case 'yes':
        room.values[0].status = '1';
        this.setState({
          room: room,
          openTextArea: false,
          checked1: true,
          checkedfault1: false
        });
        break;
      case 'no':
        room.values[0].status = 'fault';
        this.setState({
          room: room,
          openTextArea: true,
          checkedfault1: true,
          checked1: false
        });
        break;
      default:
        break;
    }
  };

  handleChange = (index, e) => {
    let room = { ...this.state.room };
    room.values[index].comment = e.target.value;

    this.setState({
      room: room
    });
  };

  componentDidMount() {
    let room = { ...this.context.state.apistate.kitchen_inspection.areas[2] };
    room.exists = true;

    this.setState({
      room: room
    });

    if (
      this.context.state.apistate.kitchen_inspection.areas[2].values[0]
        .status === '1'
    ) {
      this.setState({ checked1: true });
    }

    if (
      this.context.state.apistate.kitchen_inspection.areas[2].values[0]
        .status === 'fault'
    ) {
      this.setState({ checkedfault1: true, openTextArea: true });
    }
  }

  isDisabled() {
    if (
      this.state.room.is_checked ||
      this.state.checked1 ||
      (this.state.checkedfault1 && this.state.room.values[0].comment !== '')
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <Div100vh>
        <Wrapper>
          <TopButtons
            handleSaveNoBack={() => this.handleSaveNoBack(2, this.state.room)}
            helplink="/help-kitchen-inspection"
            backgroundColor="#002837"
            handleBack={() => this.handleBack()}
          >
            <div style={{ display: 'flex' }}>
              <MaterialIcon icon="location_city" size="28px" color="#e2edf5" />
              <span style={{ marginLeft: '15px' }}>
                {`Lgh ${this.context.state.apistate.rental_object_appartment_number}`}{' '}
                {this.context.state.apistate.rental_object_address}
              </span>
            </div>
          </TopButtons>

          <div style={{ width: '100%', minHeight: 'calc(100vh - 220px)' }}>
            <Headline>
              <h1>Diskmaskin</h1>
            </Headline>

            <div style={{ width: '100%', padding: '0 30px' }}>
              <Text>
                {
                  this.context.state.apistate.kitchen_inspection.areas[2]
                    .values[0].headline
                }
              </Text>

              <RadioWrapper>
                <Container>
                  <input
                    disabled={this.state.room.is_checked}
                    checked={this.state.checked1}
                    className="yes radio-checkbox"
                    type="checkbox"
                    id="yes"
                    name="radio1"
                  />
                  <label
                    onClick={
                      this.state.room.is_checked
                        ? null
                        : e => this.handleRadio(e, 'yes')
                    }
                    style={{
                      marginRight: '10px',
                      backgroundColor: `${
                        this.state.checked1 ? '#52b259' : '#fff'
                      }`,
                      color: `${this.state.checked1 ? '#fff' : '#002837'}`
                    }}
                    htmlFor="yes"
                  >
                    Ja
                  </label>

                  <input
                    disabled={this.state.room.is_checked}
                    checked={this.state.checkedfault1}
                    className="no radio-checkbox"
                    type="checkbox"
                    id="no"
                    name="radio1"
                  />
                  <label
                    onClick={
                      this.state.room.is_checked
                        ? null
                        : e => this.handleRadio(e, 'no')
                    }
                    htmlFor="no"
                    style={{
                      backgroundColor: `${
                        this.state.checkedfault1 ? '#d46369' : '#fff'
                      }`,
                      color: `${this.state.checkedfault1 ? '#fff' : '#002837'}`
                    }}
                  >
                    Nej
                  </label>
                </Container>
              </RadioWrapper>

              {this.state.openTextArea ? (
                <TextArea
                  placeholder="Du måste skriva en kommentar."
                  onChange={e => this.handleChange(0, e)}
                  value={this.state.room.values[0].comment}
                />
              ) : null}

              <Divider />

              <CheckBoxContainer>
                <label className="switch" htmlFor="checkbox">
                  <input
                    checked={this.state.room.is_checked}
                    onChange={() => this.handleCheckBox()}
                    type="checkbox"
                    id="checkbox"
                  />
                  <div className="slider round"></div>
                </label>
                <h3
                  style={{
                    fontSize: '18px',
                    color: '#002837',
                    marginLeft: '15px'
                  }}
                >
                  <strong>Diskmaskin finns ej.</strong>
                </h3>
              </CheckBoxContainer>
            </div>
          </div>

          <div
            onClick={() => this.handleSave(2, this.state.room)}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
              marginBottom: '40px'
            }}
          >
            {/*!this.state.room.is_checked ||
                !this.state.checked1 ||
                !this.state.checkedfault1*/}
            <Buttons
              disabled={this.isDisabled()}
              color="#52b259"
              textColor="#fff"
              message="Spara och stäng"
              style={{ width: 'calc(100% - 60px)' }}
            />
          </div>
        </Wrapper>
      </Div100vh>
    );
  }
}

StoveInspection.contextType = AppContext;

const Wrapper = styled.div`
  background: #e2edf5;
  min-width: 100%;
  min-height: 100%;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;

const Headline = styled.div`
  padding: 36px 0 0px;
  margin-bottom: 36px;
  width: 100%;
  color: #002837;

  h1 {
    font-size: 28px;
    word-wrap: nowrap;
    text-align: center;
  }
`;

const Text = styled.p`
  color: #002837;
  text-align: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 20px;
  background-color: #002837;
  opacity: 0.3;
`;

const RadioWrapper = styled.div`
  margin: 30px 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;

  .radio-checkbox {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    display: inline-block;
    background-color: #fff;
    width: 110px;
    height: 40px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #002837;

    transition: opacity 0.4s;
  }

  .yes:disabled + label {
    opacity: 0.5;
  }

  .no:disabled + label {
    opacity: 0.5;
  }
`;

const TextArea = styled.textarea`
  height: 137px;
  width: 100%;
  resize: none;
  border: 0;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 20px;
  color: #636363;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;

  .switch {
    display: inline-block;
    height: 30px;
    position: relative;
    width: 52px;
  }

  .switch input {
    display: none;
  }

  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  .slider:before {
    background-color: #fff;
    bottom: 0px;
    content: '';
    height: 28px;
    left: 0px;
    position: absolute;
    transition: 0.4s;
    width: 28px;
    border: 1px solid rgba(0, 40, 55, 0.51);
  }

  input:checked + .slider {
    background-color: #66bb6a;
  }

  input:checked + .slider:before {
    transform: translateX(22px);
  }

  .slider.round {
    border-radius: 3px;
  }

  .slider.round:before {
    border-radius: 3px;
  }
`;
