import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';

export default class HowItWorks extends React.Component {
    render() {
        return (
            <Wrapper>
                <Icon
                    color={ this.props.color }
                    onClick={ this.props.handleBack }
                    className="material-icons"
                    style={ { fontSize: '22px', height: '40px', width: '40px' } }
                >
                    keyboard_arrow_left
                </Icon>

                <Header>{ this.props.header }</Header>
                { this.props.children }
            </Wrapper>
        );
    }
}

HowItWorks.contextType = AppContext;

const Icon = styled.i`
    font-family: 'Material Icons Outlined', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background: transparent;
    color: ${ props => props.color };
`;

const Wrapper = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 15px;
    background-color: #002837;
`;

const Header = styled.h1`
    color: white;
    text-align: center;
    margin: 5px 0 45px;
    font-weight: 600;
`;
