import React from 'react';
import styled from 'styled-components';
import {AppContext} from '../context/Context';
import Div100vh from 'react-div-100vh';
import Buttons from '../components/TopButtons';
import Video from '../components/Video';

export default class EndPage extends React.Component {
    render() {

        const params = new URLSearchParams(this.props.location.search);
        const firstname = params.get('firstname');
        return (
            <Div100vh>
                <Video />
                <Overlay />
                <Gradient />
                <Wrapper>
                    <Buttons questionmarkremove />
                    <h1>Tack {firstname}</h1>
                    <p>
                        Vi har mottagit din besiktning och du bör ha fått en
                        bekräftelse.
                        <br />
                        <br />
                        När vi har granskat alla steg återkopplar vi via SMS.
                    </p>
                </Wrapper>
            </Div100vh>
        );
    }
}

EndPage.contextType = AppContext;

const Overlay = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    background-color: #002837;
    opacity: 0.5;
    z-index: -1;
`;

const Gradient = styled.div`
    background: linear-gradient(180deg, rgba(0, 40, 55, 0) 0%, #002837 100%);
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    color: white;
    z-index: -1;
`;

const Wrapper = styled.div`
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0 50px;

    h1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 44px;
        margin-bottom: 4px;
    }

    p {
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 30px;
    }

    h2 {
        font-size: 26px;
        line-height: 44px;
        text-align: center;
        font-weight: 500;
    }
`;
