import React from 'react';
import styled from 'styled-components';
import clip from '../assets/video.webm';
import Div100vh from 'react-div-100vh';

export default class Video extends React.Component {
  render() {
    return (
      <Div100vh style={{ zIndex: '-2' }}>
        <VideoWrp>
          <BackgroundVideo
            id="background-video"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={clip} type="video/webm" />
            <source src={clip} type="video/ogg" />
          </BackgroundVideo>
        </VideoWrp>
      </Div100vh>
    );
  }
}

const VideoWrp = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -2;
`;

const BackgroundVideo = styled.video`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  filter: grayscale(100%) contrast(300%);
  -webkit-filter: grayscale(100%) contrast(300%);
  transform: scale(2);
`;
