import React, { Component } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';
import { Link } from 'react-router-dom';
import MaterialIcon from '../components/MaterialIcon';
import Video from '../components/Video';
import Div100vh from 'react-div-100vh';
export default class UserSettings extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <Video />
        <Overlay />
        <Gradient />
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <BackButtonWrapper>
            <Icon
              onClick={this.handleBack}
              className="material-icons"
              style={{
                fontSize: '22px',
                color: 'white',
                height: '40px',
                width: '40px'
              }}
            >
              keyboard_arrow_left
            </Icon>
          </BackButtonWrapper>

          <LoggedInAs>{this.context.state.lang.Settings.LoggedIn}</LoggedInAs>
          <LoggedInUser>
            {this.context.state.apistate.tenant_firstname +
              ' ' +
              this.context.state.apistate.tenant_lastname}
          </LoggedInUser>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MaterialIcon
              icon="location_city"
              size="48px"
              color="#e2edf5"
              style={{ position: 'relative', top: '6px' }}
            />
          </div>

          <ApartmentWrapper style={{ color: '#002837' }}>
            <p style={{ fontWeight: '600' }}>
              Lgh {this.context.state.apistate.rental_object_appartment_number}
            </p>
            <h2>{this.context.state.apistate.rental_object_address}</h2>
            <p style={{ fontWeight: '600', marginTop: '5px' }}>
              {this.context.state.apistate.rental_object_zipcode +
                ' ' +
                this.context.state.apistate.rental_object_city}
            </p>
          </ApartmentWrapper>

          <Options>
            <Link to="/language">
              <Option>{this.context.state.lang.Settings.Language}</Option>
            </Link>
            <Link to="/about">
              <Option>{this.context.state.lang.Settings.About}</Option>
            </Link>
            <Link to="/contact">
              <Option>{this.context.state.lang.Settings.Contact}</Option>
            </Link>
          </Options>
        </div>
      </Div100vh>
    );
  }
}

UserSettings.contextType = AppContext;

const Overlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  background-color: #002837;
  opacity: 0.5;
  z-index: -1;
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 40, 55, 0) 0%, #002837 100%);
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  color: white;
  z-index: -1;
`;

const Icon = styled.i`
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
`;

const BackButtonWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 15px;
  display: flex;
  align-items: center;
`;

const LoggedInAs = styled.p`
  display: flex;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 3px;
`;

const LoggedInUser = styled.h1`
  display: flex;
  justify-content: center;
  color: white;
  margin: 5px 0 25px;
  font-weight: 600;
`;

const ApartmentWrapper = styled.div`
  background: #e2edf5;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Options = styled.div`
  flex: 1 1 auto;
  background: white;
`;

const Option = styled.div`
  width: 75%;
  text-align: center;
  color: #002837;
  border-bottom: 0.5px solid lightgray;
  padding: 25px;
  margin: 0 auto;
  font-weight: 500;
`;
