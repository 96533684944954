import React, { Component } from 'react';
import styled from 'styled-components';
import MaterialIcon from './MaterialIcon';
import Button from './Buttons';
import { AppContext } from '../context/Context';

export default class QuestionMarkView extends Component {
  handleBack = () => {
    if (this.props.index === 0 || this.props.index) {
      if (
        this.context.state.apistate.inspection_data[this.props.index].images
          .length > 0
      ) {
        this.context.reportFlawSaved(true);
      }
    }

    this.props.goBack();
  };

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Wrapper style={this.styles}>
          <Header>
            <Icon onClick={this.handleBack}>
              <MaterialIcon
                icon="keyboard_arrow_left"
                size="22px"
                color="rgba(0, 40, 55, 0.7)"
              />
            </Icon>
            <p>Information</p>
          </Header>
          <Content>
            <div>{this.props.children}</div>
          </Content>
        </Wrapper>
        <div style={{ margin: '0 30px 35px' }}>
          <Button onClick={this.handleBack} message="Tillbaka" color="#E2EDF5">
            Tillbaka
          </Button>
        </div>
      </div>
    );
  }
}

QuestionMarkView.contextType = AppContext;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 25px 15px;
  background: rgb(246, 250, 252);
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;

  p {
    color: rgba(0, 40, 55, 0.7);
    height: 45px;
    line-height: 2.5;
  }
`;

const Icon = styled.div`
  position: absolute;
  left: 15px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background: #fff;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
`;
