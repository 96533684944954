import React from 'react';

import sv from '../language/lang-sv';
import en from '../language/lang-en';
import ara from '../language/lang-ara';
import fas from '../language/lang-fas';

export const AppContext = React.createContext();
export default class AppProvider extends React.Component {
  state = {
    isLoggedIn: true,
    lang: sv,
    langName: 'SV',
    buttonContent: 'Sv',
    toggleLang: false,
    apistate: {},
    fetched: false,
    reportFlawSaved: false,
    rooms: null
  };

  setStateRooms = room => {
    let state = room;

    this.setState({
      rooms: state
    });
  };

  toggleLang = () => {
    this.setState({
      ...this.state,
      toggleLang: !this.state.toggleLang
    });
    sessionStorage.setItem('selectedlanguage', true);
  };

  changeLang = selectedLang => {
    if (selectedLang === 'sv') {
      this.setState({
        ...this.state,
        lang: sv,
        langName: 'SV',
        buttonContent: 'Sv'
      });
    } else if (selectedLang === 'en') {
      this.setState({
        ...this.state,
        lang: en,
        langName: 'EN',
        buttonContent: 'En'
      });
    } else if (selectedLang === 'ara') {
      this.setState({
        ...this.state,
        lang: ara,
        langName: 'ARA',
        buttonContent: 'Ara'
      });
    } else if (selectedLang === 'fas') {
      this.setState({
        ...this.state,
        lang: fas,
        langName: 'FAS',
        buttonContent: 'Fas'
      });
    }
  };

  signIn = () => {
    this.setState({
      ...this.state,
      isLoggedIn: true
    });
  };

  signOut = () => {
    this.setState({
      ...this.state,
      isLoggedIn: false
    });
  };

  kitchenInspection = (room, state) => {
    let kitchenstate = this.state.apistate;
    kitchenstate.kitchen_inspection.areas[room] = state;

    this.setState({
      ...this.state,
      apistate: kitchenstate
    });
  };

  setApiState = state => {
    this.setState({
      ...this.state,
      apistate: state
    });
  };

  setFetched = () => {
    this.setState({
      fetched: true
    });
  };

  handleCleaningCheck = (index, n) => {
    let cleaningState = JSON.parse(JSON.stringify(this.state.apistate));
    cleaningState.cleaning_inspection.areas[index].completed = true;
    if (n === 1) {
      cleaningState.cleaning_inspection.areas[index].checked_item = true;
    } else if (n === 2) {
      cleaningState.cleaning_inspection.areas[index].checked_item = false;
    }

    this.setState({
      apistate: cleaningState
    });
  };

  addImageToApiState = (room, imageId, newImage) => {
    let initialState = JSON.parse(JSON.stringify(this.state.apistate));

    initialState.inspection_data.forEach(roomitem => {
      if (room.room_name === roomitem.room_name) {
        roomitem.images.forEach(roomItemImage => {
          if (roomItemImage.id === imageId) {
            roomItemImage = newImage;
          }
        });
      }
    });

    this.setState({
      apistate: initialState
    });
  };

  reportFlawSaved = bool => {
    this.setState({
      reportFlawSaved: bool
    });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          setStateRooms: this.setStateRooms,
          changeLang: this.changeLang,
          toggleLang: this.toggleLang,
          signIn: this.signIn,
          signOut: this.signOut,
          addRoom: this.addRoom,
          kitchenInspection: this.kitchenInspection,
          setApiState: this.setApiState,
          setFetched: this.setFetched,
          addImageToRoom: this.addImageToRoom,
          handleCleaningCheck: this.handleCleaningCheck,
          reportFlawSaved: this.reportFlawSaved
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
