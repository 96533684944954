import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';
import Logo from '../components/Logo';
import TextCarousel from '../components/TextCarousel';
import Div100vh from 'react-div-100vh';
import Video from '../components/Video';

export default class Login extends React.Component {
  signIn = () => {
    this.context.signIn();
  };

  render() {
    return (
      <Div100vh>
        <Video />
        <Overlay />
        <Gradient />
        <Wrapper>
          <Logo />
          <TextCarousel />
        </Wrapper>
      </Div100vh>
    );
  }
}

Login.contextType = AppContext;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const Overlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  background-color: #002837;
  opacity: 0.5;
  z-index: -1;
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 40, 55, 0) 0%, #002837 100%);
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  color: white;
  z-index: -1;
`;
