import React from 'react';
import styled from 'styled-components';
import {AppContext} from '../context/Context';
import {TopNavigation} from '../components/TopNavigation';
import Camera from '../components/Camera';
import MaterialIcon from '../components/MaterialIcon';
import Buttons from '../components/Buttons';
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';
import RoomList from '../components/RoomList';
import Div100vh from 'react-div-100vh';
import {FullscreenLoader} from '../components/FullscreenLoader';

export default class Room extends React.Component {
    state = {
        inputs: [1, 2, 3, 4],
        images: [],
        imagesFromApi: [],
        showImage: false,
        activeImage: '',
        showSuccessDialog: false,
        currentImage: 0,
        flawsCount: 0,
        kitchenInspectionCompleted: false,
        showComment: false,
        comment: '',
        isSaving: false,
    };

    handleImage = (image, index, apiImage) => {
        if (apiImage) {
            apiImage.image_url = null;
            apiImage.image = image;
        } else {
            apiImage = {
                image: image,
                name: image.name,
                id: null,
                image_type: 'normal',
                comment: '',
                status: 0,
            };

            this.setState({
                currentImage: this.state.currentImage + 1,
            });
        }

        let images = [...this.state.images];
        images.splice(index, 1, apiImage);

        this.setState({
            images: images,
        });
    };

    showImage = image => {
        this.setState({
            showImage: true,
            activeImage: image,
        });
    };

    addImage() {
        this.setState({
            inputs: [...this.state.inputs, this.state.inputs.length + 1],
        });
    }

    handleChange = e => {
        this.setState({
            comment: e.target.value,
        });
    };

    handleSave = () => {
        this.setState({isSaving: true});
        this.context.reportFlawSaved(false);

        const initialState = JSON.parse(
            JSON.stringify(this.context.state.apistate),
        );

        const roomInfo = this.context.state.apistate.inspection_data[
            this.props.location.state.index
            ];

        roomInfo.tenant_comment = this.state.comment;

        initialState.inspection_data = [roomInfo];

        const apiImages = JSON.parse(
            JSON.stringify(initialState.inspection_data[0].images),
        );

        const images = this.state.images;

        const formData = new FormData();

        for (const [i, imageObj] of images.entries()) {
            Object.defineProperty(imageObj.image, 'name', {
                value: imageObj.image.name,
                writable: true,
            });
            let imageIsNew = true;
            let match = false;

            const now = Date.now();

            if (apiImages.length > 0) {
                for (const globalStateImage of apiImages) {
                    if (
                        globalStateImage.id === imageObj.id &&
                        globalStateImage.id !== null
                    ) {
                        globalStateImage.image =
                            `${i}-${now}-${imageObj.image.name.replace(
                                /\s/g,
                                '',
                            )}`;
                        globalStateImage.comment = '';
                        globalStateImage.image_type = 'normal';
                        globalStateImage.image_url = null;

                        imageObj.image.name =
                            `${i}-${now}-${imageObj.image.name.replace(
                                /\s/g,
                                '',
                            )}`;
                        formData.append(
                            'files',
                            imageObj.image,
                            `${imageObj.image.name.replace(/\s/g, '')}`,
                        );

                        imageIsNew = false;
                        match = true;
                    }
                }

                if (match === false) {
                    const newImage = {
                        id: null,
                        url: null,
                        image: `${i}-${now}-${imageObj.image.name.replace(
                            /\s/g,
                            '',
                        )}`,
                        image_type: 'normal',
                        comment: '',
                        status: 0,
                        index_group: 0,
                    };

                    apiImages.push(newImage);

                    imageObj.image.name =
                        `${i}-${now}-${imageObj.image.name.replace(
                            /\s/g,
                            '',
                        )}`;

                    formData.append(
                        'files',
                        imageObj.image,
                        `${imageObj.image.name.replace(/\s/g, '')}`,
                    );

                    imageIsNew = false;
                }
            }

            if (imageIsNew === true) {
                const newImage = {
                    id: null,
                    url: null,
                    image: `${i}-${now}-${imageObj.image.name.replace(
                        /\s/g,
                        '',
                    )}`,
                    image_type: 'normal',
                    comment: '',
                    status: 0,
                    index_group: 0,
                };

                apiImages.push(newImage);

                imageObj.image.name =
                    `${i}-${now}-${imageObj.image.name.replace(
                        /\s/g,
                        '',
                    )}`;

                formData.append(
                    'files',
                    imageObj.image,
                    `${imageObj.image.name.replace(/\s/g, '')}`,
                );
            }
        }

        initialState.inspection_data[0].images = apiImages;

        formData.append('data', JSON.stringify(initialState));

        const options = {
            url: `${process.env.REACT_APP_API_URL}/app/createInspectionDataRoom`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        };

        return axios(options)
            .then(response => this.context.setApiState(response.data))
            .finally(() => this.setState({isSaving: false}));
    };

    handleSaveAndGoBack = () => {
        this.handleSave()
            .then(() => this.props.history.goBack());
    };

    handleSaveAndGoToURL = (url, state) => {
        this.handleSave()
            .then(() => this.props.history.push(url, state));
    };

    handleSaveAndShowSuccessDialog = () => {
        this.handleSave().then(() => {
            this.setState({
                showSuccessDialog: true,
            });

            setTimeout(() => {
                this.setState({
                    showSuccessDialog: false,
                });
            }, 1250);

            setTimeout(() => {
                this.props.history.goBack();
            }, 1750);
        });
    };

    getHelpLink = () => {
        switch (this.props.location.state.room_type) {
            case 'hall':
                return '/help-hall';

            case 'kitchen':
                return '/help-kitchen';

            case 'room':
                return '/help-room';

            case 'bath':
                return '/help-bathroom';

            case 'storage':
                return '/help-storage';

            case 'balcony':
                return '/help-balcony';

            default:
                break;
        }
    };

    cameraInput = () => {
        const normalImages = [];

        this.context.state.apistate.inspection_data[
            this.props.location.state.index
            ].images.forEach(image => {
            if (image.image_type === 'normal') {
                normalImages.push(image);
            }
        });

        return this.state.inputs.map((key, index) => {
            if (index > normalImages.length + this.state.currentImage) {
                return (
                    <Camera
                        background="transparent"
                        visible
                        cameraColor="#002837"
                        text={'Bild ' + (index + 1)}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                        disabled
                    />
                );
            }

            if (
                this.context.state.apistate.inspection_data[
                    this.props.location.state.index
                    ].images.length
            ) {
                return (
                    <Camera
                        background="white"
                        cameraColor="#186096"
                        text={'Bild ' + (index + 1)}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                    />
                );
            } else if (index === this.state.currentImage) {
                return (
                    <Camera
                        background="white"
                        cameraColor="#186096"
                        text={'Ta bild'}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                    />
                );
            } else if (index < this.state.currentImage) {
                return (
                    <Camera
                        background="white"
                        cameraColor="#186096"
                        text={'Bild ' + (index + 1)}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                    />
                );
            } else if (index > this.state.currentImage) {
                return (
                    <Camera
                        background="transparent"
                        visible
                        cameraColor="#002837"
                        text={'Bild ' + (index + 1)}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                        disabled
                    />
                );
            }
            return null;
        });
    };

    saveDisabled = () => {
        if (this.state.isSaving) {
            return true;
        }

        const localImages = this.state.images;

        const inspectionType = this.context.state.apistate.inspection_type;
        const roomType = this.props.location.state.room_type;

        if (
            roomType === 'kitchen' &&
            inspectionType !== 'pre' &&
            this.context.state.apistate.kitchen_inspection != null
        ) {
            return !this.context.state.apistate.kitchen_inspection.areas.every(
                item => {
                    return item.is_completed === true;
                },
            );
        }

        if (localImages.length > 0) {
            return false;
        }

        return !this.context.state.reportFlawSaved;
    };

    componentDidMount() {
        const normalImages = [];

        this.context.state.apistate.inspection_data[
            this.props.location.state.index
            ].images.forEach(image => {
            if (image.image_type === 'normal') {
                normalImages.push(image);
            }
        });

        const images = normalImages;

        if (images.length === 1) {
            this.setState({
                imagesFromApi: images,
                inputs: [...images, 2, 3, 4],
            });
        }

        if (images.length === 2) {
            this.setState({
                imagesFromApi: images,
                inputs: [...images, 3, 4],
            });
        }

        if (images.length === 3) {
            this.setState({
                imagesFromApi: images,
                inputs: [...images, 4],
            });
        }

        if (images.length > 3) {
            this.setState({
                imagesFromApi: images,
                inputs: [...images],
            });
        }

        let flawsCount = 0;

        this.context.state.apistate.inspection_data[
            this.props.location.state.index
            ].images.forEach(image => {
            if (image.image_type === 'flaw') {
                flawsCount = 1;
                if (image.index_group + 1 > flawsCount) {
                    flawsCount = image.index_group + 1;
                }
            }
        });

        this.setState({
            flawsCount: flawsCount,
        });

        if (this.context.state.apistate.inspection_type !== 'measure') {
            if (
                this.context.state.apistate.kitchen_inspection.areas.every(
                    item => {
                        return item.is_completed === true;
                    })
            ) {
                this.setState({
                    kitchenInspectionCompleted: true,
                });
            }
        }

        if (this.context.state.apistate.status_completion < 3) {
            setTimeout(() => {
                this.setState({
                    showComment: true,
                });
            }, 600);
        }

        this.setState({
            comment: this.context.state.apistate.tenant_comment,
        });
    }

    render() {
        const inspectionType = this.context.state.apistate.inspection_type;
        const inspectionStatus = this.context.state.apistate.status_inspection;
        const roomType = this.props.location.state.room_type;

        const isMeasuring = inspectionType === 'measure';
        const isPre = inspectionType === 'pre';
        const isReInspection = inspectionStatus === 2 || inspectionStatus === 3;
        const isKitchen = roomType === 'kitchen';

        return (
            <Div100vh>
                <FullscreenLoader visible={this.state.isSaving} />
                <Wrapper
                    style={
                        !isMeasuring && !isReInspection
                            ? {minHeight: 'calc(100% - 65px)'}
                            : {minHeight: '100%'}
                    }
                >
                    <div>
                        <TopNavigation
                            onClickBack={() => this.handleSaveAndGoBack()}
                            onClickHelp={() => this.handleSaveAndGoToURL(
                                this.getHelpLink(),
                                {
                                    index: this.props.location.state.index,
                                },
                            )}
                            background="#002837"
                        >
                            <div style={{display: 'flex'}}>
                                <MaterialIcon
                                    icon="location_city"
                                    size="28px"
                                    color="#e2edf5"
                                />
                                <span style={{marginLeft: '15px'}}>
                                    {`Lgh ${this.context.state.apistate.rental_object_appartment_number}`}{' '}
                                    {this.context.state.apistate.rental_object_address}
                                </span>
                            </div>
                        </TopNavigation>
                    </div>

                    {isMeasuring && (
                        <SweetAlert
                            show={isMeasuring && this.state.showComment}
                            title="Åtgärdsinformation"
                            text={
                                this.context.state.apistate.inspection_data[
                                    this.props.location.state.index
                                    ].room_information
                            }
                            onConfirm={() => this.setState(
                                {showComment: false})}
                        />
                    )}

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            minHeight: 'calc(100vh - 285px)',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Headline>
                            <h1>{this.props.location.state.room}</h1>
                        </Headline>

                        <div style={{width: '100%', marginTop: '36px'}}>
                            {this.cameraInput()}
                            {this.state.inputs.length < 10 && (
                                <i
                                    style={{width: '100%'}}
                                    onClick={() => this.addImage()}
                                >
                                    <RoomList
                                        color="rgb(0, 40, 55)"
                                        room="Lägg till bild"
                                        icon="add"
                                        backgroundColor="#002837"
                                    />
                                </i>
                            )}
                        </div>

                        {isMeasuring && (
                            <TextArea
                                placeholder={
                                    this.context.state.apistate.inspection_data[
                                        this.props.location.state.index
                                        ].tenant_comment
                                }
                                onChange={e => this.handleChange(e)}
                                value={this.state.comment}
                            />
                        )}

                        {isKitchen && !isPre && (
                            <div style={{width: '100%', padding: '0 30px'}}>
                                <Divider />
                                <div
                                    onClick={() => this.handleSaveAndGoToURL(
                                        '/whitegoods')}
                                >
                                    <ChecklistWrapper>
                                        <InnerWrapper>
                                            <ChecklistInnerWrapper>
                                                <ChecklistInput />

                                                <Text>
                                                    Kontrollera vitvaror
                                                </Text>

                                                <IconWrp
                                                    style={{
                                                        background: `${
                                                            this.state.kitchenInspectionCompleted
                                                                ? '#52b259'
                                                                : '#186096'
                                                        }`,
                                                    }}
                                                >
                                                    <MaterialIcon
                                                        icon={
                                                            this.state.kitchenInspectionCompleted
                                                                ? 'check'
                                                                : 'search'
                                                        }
                                                        size="24px"
                                                        color="white"
                                                    />
                                                </IconWrp>
                                            </ChecklistInnerWrapper>
                                        </InnerWrapper>
                                    </ChecklistWrapper>
                                </div>
                            </div>
                        )}
                    </div>

                    <SweetAlert
                        show={this.state.showSuccessDialog}
                        type="success"
                        title="Rummet sparat"
                        onConfirm={() => this.setState({
                            showSuccessDialog: false,
                        })}
                        showConfirmButton={false}
                    />

                    {this.state.showImage && (
                        <ImageWrapper>
                            <div style={{position: 'relative'}}>
                                <Close
                                    onClick={() => {
                                        this.setState({
                                            showImage: false,
                                            activeImage: '',
                                        });
                                    }}
                                >
                                    <MaterialIcon
                                        icon="close"
                                        size="26px"
                                        color="rgba(0, 40, 55, 1)"
                                    />
                                </Close>
                                <FullImage src={this.state.activeImage} />
                            </div>
                        </ImageWrapper>
                    )}

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                            marginBottom: '40px',
                        }}
                    >
                        <Buttons
                            onClick={() => this.handleSaveAndShowSuccessDialog()}
                            disabled={this.saveDisabled()}
                            color="#52b259"
                            textColor="#fff"
                            message="Spara och stäng"
                            style={{width: 'calc(100% - 60px)'}}
                        />
                    </div>
                </Wrapper>

                {!isMeasuring && !isReInspection && (
                    <div
                        onClick={() => this.handleSaveAndGoToURL(
                            '/report-flaw',
                            {
                                room: this.props.location.state.room,
                                index: this.props.location.state.index,
                                uniqueID: this.props.location.state.uniqueID,
                            },
                        )}
                    >
                        <ReportFlaw>
                            {this.state.flawsCount > 0 && (
                                <FlawCount>{this.state.flawsCount}</FlawCount>
                            )}
                            <div>
                                <MaterialIcon
                                    icon="report"
                                    size="28px"
                                    color="#fff"
                                />
                                <span>Rapportera brist i {this.props.location.state.room}</span>
                                <MaterialIcon
                                    icon="keyboard_arrow_up"
                                    size="28px"
                                    color="#fff"
                                />
                            </div>
                        </ReportFlaw>
                    </div>
                )}
            </Div100vh>
        );
    }
}

Room.contextType = AppContext;

const Wrapper = styled.div`
    background: #e2edf5;
    min-width: 100%;
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
`;

const Headline = styled.div`
    padding: 30px 0 0px;
    width: 100%;
    color: #002837;

    h1 {
        font-size: 28px;
        word-wrap: nowrap;
        text-align: center;
        margin: 0;
    }
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin: 20px 0 20px;
    background-color: #002837;
    opacity: 0.3;
`;

const ChecklistWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

const InnerWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ChecklistInnerWrapper = styled.div`
    position: relative;
    height: 60px;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    overflow: hidden;
`;

const ChecklistInput = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
`;

const Text = styled.span`
    position: absolute;
    color: #002837;
    margin-left: 20px;
    font-size: 18px;
`;

const IconWrp = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    top: -5;
    right: 0;
    background-color: #186096;
    border-radius: 0 3px 3px 0;
`;

const ImageWrapper = styled.div`
    position: absolute;
    background: #e2edf5;
    color: #fff;
    height: calc(100% - 90px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

const FullImage = styled.img`
    width: 100%;
`;

const Close = styled.div`
    height: 24px;
    width: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
`;

const ReportFlaw = styled.div`
    width: 100vw;
    padding: 15px 0 20px;
    background: #d46369;
    display: flex;
    justify-content: center;
    position: relative;

    div {
        display: flex;
    }

    span {
        color: #ffffff;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        margin: 0 15px;
    }
`;

const FlawCount = styled.div`
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    top: -20px;
    right: 17px;
    background: #d46369;
    color: white;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextArea = styled.textarea`
    height: 137px;
    width: calc(100% - 60px);
    resize: none;
    border: 0;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 20px;
    color: #636363;
`;
