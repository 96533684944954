import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';

import img from '../assets/logo_icon-login.svg';
export default class Logo extends React.Component {
  render() {
    return (
      <LogoWrapper>
        <LogoImage />
        <LogoText>{this.context.state.lang.StartPage.AppName}</LogoText>
      </LogoWrapper>
    );
  }
}

Logo.contextType = AppContext;

const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const LogoImage = styled.div`
  margin: 0 auto 15px;
  background: url(${img});
  width: 80px;
  height: 80px;
`;

const LogoText = styled.h1`
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 45px;
  height: 45px;
  line-height: 1;
`;
