import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';

function useDelayedValue(value, delay) {
    const [delayedValue, setDelayedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => setDelayedValue(value), delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [delay, value]);

    return delayedValue;
}

function FullscreenLoader({visible}) {
    const delayedVisibility = useDelayedValue(visible, 200);
    const isFullscreenLoaderVisible = !visible
        ? visible
        : delayedVisibility;
    return (
        <Wrapper block={visible} show={isFullscreenLoaderVisible}>
            <i className="fal fa-spinner-third fa-spin" />
        </Wrapper>
    );
}

export {FullscreenLoader};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;

    display: flex;
    align-items: center;
    justify-content: center;

    background: black;

    opacity: 0;
    visibility: hidden;

    pointer-events: none;

    transition: opacity 0.2s, visibility 0.2s;

    ${props => props.block && css`
        pointer-events: all;
        visibility: visible;
    `}

    ${props => props.show && css`
        opacity: 0.3;
    `};

    i {
        color: #E2EDF5;
        font-size: 50px;
    }
`;
