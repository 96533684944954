import React, { Component } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';
import Button from '../components/Buttons';
import MaterialIcon from '../components/MaterialIcon';
import Div100vh from 'react-div-100vh';

export default class ShowCleaningFlaw extends Component {
  //{id: 10, image_type: "normal", image: "0-photo-1523755231516-e43fd2e8dca5.jpg", image_url: "https://min-besiktning-api.tbnetcore02.tankbar.com…tion_12308/0-photo-1523755231516-e43fd2e8dca5.jpg", comment: null}

  state = {
    showImage: false,
    activeImage: '',
    comment: ''
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  getCurrentRoom = () => {
    let currentRoom;

    this.context.state.apistate.inspection_data.forEach(room => {
      if (room.id === this.props.location.state.room) {
        currentRoom = room;
      }
    });

    return currentRoom;
  };

  listCurrentRoomImages = () => {
    let room = this.getCurrentRoom();
    let cleaningFlawImages = [];

    room.images.forEach(image => {
      if (image.image_type === 'cleaning') {
        cleaningFlawImages.push(image);
      }
    });

    return cleaningFlawImages;
  };

  showImage = url => {
    this.setState({
      showImage: true,
      activeImage: url
    });
  };

  getComment = () => {
    const room = this.getCurrentRoom();

    return room.images.forEach(image => {
      if (image.image_type === 'cleaning') {
        return image.comment;
      }
    });
  };

  render() {
    return (
      <Div100vh>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <ReportFlawHeader onClick={() => this.handleBack()}>
            <MaterialIcon icon="report" size="28px" color="#fff" />
            <span>
              Bristfällig städning i {this.props.location.state.roomName}
            </span>
            <MaterialIcon icon="keyboard_arrow_up" size="28px" color="#fff" />
          </ReportFlawHeader>

          <Wrapper>
            <h1
              style={{
                width: '100%',
                marginTop: '30px',
                marginBottom: '30px',
                textAlign: 'center'
              }}
            >
              <Text>Brister i städning</Text>
            </h1>

            {this.listCurrentRoomImages().map((item, index) => {
              return (
                /*<RoomList
                  color="rgb(0, 40, 55)"
                  room={`Brist ${index}`}
                  icon="check"
                  backgroundColor="rgb(82, 178, 89)"
                />*/
                <CleaningImageWrapper>
                  <div
                    onClick={() => this.showImage(`${item.image_url}`)}
                    className="cleaning-image"
                    style={{ backgroundImage: `url(${item.image_url})` }}
                  ></div>
                  <CleaningFlawImage>
                    <CleaningFlawText>{`Brist ${index}`}</CleaningFlawText>

                    <CleaningFlawButton>
                      <MaterialIcon icon="check" size="24px" color="white" />
                    </CleaningFlawButton>
                  </CleaningFlawImage>
                </CleaningImageWrapper>
              );
            })}

            <TextArea readOnly value={this.getComment()} />

            {this.state.showImage ? (
              <ImageWrapper>
                <div style={{ position: 'relative' }}>
                  <Close
                    onClick={() => {
                      this.setState({
                        showImage: false,
                        activeImage: ''
                      });
                    }}
                  >
                    <MaterialIcon
                      icon="close"
                      size="26px"
                      color="rgba(0, 40, 55, 1)"
                    />
                  </Close>
                  <FullImage src={this.state.activeImage}></FullImage>
                </div>
              </ImageWrapper>
            ) : null}

            <BtnWrp>
              <Button
                style={{ marginRight: '7.5px' }}
                onClick={this.handleBack}
                message={this.context.state.lang.InfoPage.Tab2.Button}
                color="#fff"
              />
              <Button
                style={{ marginLeft: '7.5px', color: 'white' }}
                onClick={this.handleBack}
                message="Spara"
                color="#51B35A"
              />
            </BtnWrp>
          </Wrapper>
        </div>
      </Div100vh>
    );
  }
}

ShowCleaningFlaw.contextType = AppContext;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #e2edf5;
  color: white;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  .intro-text {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

const Text = styled.p`
  color: #002837;
  width: 100%;
  padding: 0 30px;
`;

const BtnWrp = styled.div`
  width: 75%;
  margin-bottom: 25px;
  display: flex;
  position: absolute;
  bottom: 0%;
`;

const ReportFlawHeader = styled.div`
  width: 100vw;
  padding: 50px 0 20px;
  background: #d46369;
  display: flex;
  justify-content: center;

  div {
    display: flex;
  }

  span {
    color: #ffffff;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    margin: 0 15px;
    color: fff;
  }
`;

const CleaningImageWrapper = styled.div`
  width: calc(100% - 60px);
  display: flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 10px;

  .cleaning-image {
    height: 60px;
    width: 45px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }
`;

const CleaningFlawImage = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 3px;
  background-color: white;
  position: relative;
  width: calc(100% - 55px);
`;

const CleaningFlawText = styled.p`
  font-size: 18px;
  color: rgb(0, 40, 55);
  padding-left: 20px;
`;

const CleaningFlawButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  top: -5;
  right: 0;
  background-color: rgb(82, 178, 89);
  border-radius: 0 3px 3px 0;
`;

const ImageWrapper = styled.div`
  position: absolute;
  background: #e2edf5;
  color: #fff;
  height: calc(100% - 90px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const FullImage = styled.img`
  width: 100%;
`;

const Close = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const TextArea = styled.textarea`
  height: 137px;
  width: calc(100% - 60px);
  resize: none;
  border: 0;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 20px;
  color: #636363;
`;
