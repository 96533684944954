import React, { Component } from 'react';
import styled from 'styled-components';

export default class MaterialIcon extends Component {
  render() {
    return (
      <Icon
        icon={this.props.icon}
        size={this.props.size}
        color={this.props.color}
        style={this.props.style}
      >
        {this.props.icon}
      </Icon>
    );
  }
}

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: ${props => props.size};
  line-height: 1;
  color: ${props => props.color};
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
`;
