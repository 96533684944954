import React from 'react';
import styled, {keyframes} from 'styled-components';
import {AppContext} from '../context/Context';
import TopButtons from '../components/TopButtonsApartment';
import MaterialIcon from '../components/MaterialIcon';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import Buttons from '../components/Buttons';
import RoomList from '../components/RoomList';
import axios from 'axios';
import Div100vh from 'react-div-100vh';

export default class Inspection extends React.Component {
    state = {
        fadeDisclaimer: false,
        showDisclaimer: !sessionStorage.getItem('disclaimershowed'),
        checked: false,
        disabled: false,
        filmOpen: !sessionStorage.getItem('disclaimershowed'),
        alertOpen: false,
        isSigningWithBankId: false,
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    closeDisclaimer = () => {
        sessionStorage.setItem('disclaimershowed', true);
        this.setState({
            fadeDisclaimer: true,
        });
        setTimeout(() => {
            this.setState({
                showDisclaimer: false,
            });
        }, 200);
    };

    checkForCleaningFlaws() {
        let result = false;

        this.context.state.apistate.inspection_data.forEach(room => {
            room.images.forEach(image => {
                if (image.image_type === 'cleaning') {
                    result = true;
                }
            });
        });

        return result;
    }

    handlePopUp() {
        this.setState({
            alertOpen: true,
        });
    }

    onChecked(e) {
        e.preventDefault();
        this.setState({checked: !this.state.checked});
    }

    handleSave() {
        this.setState({
            disabled: true,
        });

        const firstname = this.context.state.apistate.tenant_firstname;
        const dataToSave = JSON.stringify(this.context.state.apistate);
        const shouldSignWithBankId = this.context.state.apistate.bank_id_active;

        const save = (data) => {
            axios({
                url: `${process.env.REACT_APP_API_URL}/app/createInspectionData`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data,
            }).then(() => {
                this.setState({
                    isSigningWithBankId: false,
                });

                this.props.history.push(
                    `/endpage?firstname=${firstname}`);
            });
        };

        if (!shouldSignWithBankId) {
            save(dataToSave);
        } else {
            this.setState({
                isSigningWithBankId: true,
            });

            axios({
                url: `${process.env.REACT_APP_API_URL}/app/getBankId`,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: sessionStorage.params,
            }).then(async response => {
                const res = JSON.parse(response.data);

                const url = new URL(document.location);
                const redirect = encodeURIComponent(
                    `${url.origin}/endpage?firstname=${firstname}`,
                );

                document.location =
                    `bankid://?autostarttoken=${res.autoStartToken}&redirect=${redirect}`;

                const inspection_id = JSON.parse(sessionStorage.params);

                const interval = setInterval(() => {
                    const url = `${process.env.REACT_APP_API_URL}/app/getBankIdCallback?session_id=${res.sessionId}&inspection_id=${inspection_id.id}`;
                    axios
                        .get(url)
                        .then(async response => {
                            const validStatusCodes = [
                                'error',
                                'pending',
                                'success',
                            ];

                            let status = response.data
                                ? response.data.status
                                : 'error';

                            if (validStatusCodes.indexOf(status) === -1) {
                                status = 'error';
                            }

                            switch (status) {
                                case 'error':
                                    clearInterval(interval);

                                    this.setState({
                                        isSigningWithBankId: false,
                                    });

                                    alert('Något gick fel. Försök igen.');
                                    break;
                                case 'success':
                                    clearInterval(interval);

                                    this.setState({
                                        isSigningWithBankId: false,
                                    });

                                    save(dataToSave);
                                    break;
                                case 'pending':
                                default:
                                    // Just wait.
                                    break;
                            }
                        })
                        .catch(() => {
                            clearInterval(interval);

                            this.setState({
                                disabled: false,
                                isSigningWithBankId: false,
                            });

                            alert('Något gick fel. Försök igen.');
                        });
                }, 5000);
            }).catch(() => {
                this.setState({
                    disabled: false,
                    isSigningWithBankId: false,
                });

                alert('Något gick fel. Försök igen.');
            });
        }
    }

    handleSaveNoBack = () => {
        const inspection = JSON.parse(
            JSON.stringify(this.context.state.apistate));

        inspection.cleaning_inspection.processed = true;

        const options = {
            url: `${process.env.REACT_APP_API_URL}/app/createInspectionCleaningData`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: inspection,
        };

        axios(options).then(async response => {
            this.context.setApiState(response.data);
        });
    };

    renderRooms = () => {
        return this.context.state.apistate.inspection_data.map(
            (room, index) => {
                const status = this.context.state.apistate.inspection_data[index].status;
                const roomName = this.context.state.apistate.inspection_data[index].room_name;
                const roomType = this.context.state.apistate.inspection_data[index].room_type;
                const images = this.context.state.apistate.inspection_data[index].images;
                return (
                    <div key={index}>
                        {status !== 1
                            ? (
                                <Link
                                    to={{
                                        pathname: '/room',
                                        state: {
                                            room: roomName,
                                            room_type: roomType,
                                            index: index,
                                            uniqueID: `room-${index}`,
                                        },
                                    }}
                                    style={{width: '100%'}}
                                    key={index}
                                >
                                    {images.length > 0
                                        ? (
                                            <RoomList
                                                color="rgba(0, 40, 55, 0.7)"
                                                room={roomName}
                                                icon="check"
                                                backgroundColor="rgb(82, 178, 89)"
                                            >
                                                {status === 2 && (
                                                    <Overlay />
                                                )}
                                            </RoomList>
                                        ) : (
                                            <RoomList
                                                color="rgb(0, 40, 55)"
                                                room={roomName}
                                                icon="keyboard_arrow_right"
                                                backgroundColor="#186096"
                                            />
                                        )}
                                </Link>
                            ) : (
                                <div style={{width: '100%'}}>
                                    {images.length > 0
                                        ? (
                                            <RoomList
                                                color="rgba(0, 40, 55, 0.7)"
                                                room={roomName}
                                                icon="check"
                                                backgroundColor="rgb(82, 178, 89)"
                                            >
                                                {status === 2 && (
                                                    <Overlay />
                                                )}
                                            </RoomList>
                                        ) : (
                                            <RoomList
                                                color="rgb(0, 40, 55)"
                                                room={roomName}
                                                icon="keyboard_arrow_right"
                                                backgroundColor="#186096"
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                );
            });
    };

    render() {
        if (this.context.state.apistate.inspection_data == null) {
            return null;
        }

        const inspectionStatus = this.context.state.apistate.status_inspection;
        const inspectionType = this.context.state.apistate.inspection_type;

        const showCheckList = this
            .context
            .state
            .apistate
            .inspection_data
            .some(r => r.images.length);

        const cleaningInspectionProcessed = this.context.state.apistate.cleaning_inspection.processed;
        return (
            this.state.filmOpen && inspectionStatus === 0 ? (
                <VideoWrapper className="intro-guide">
                    {this.state.alertOpen && (() => {
                        Swal.fire({
                            title:
                                'Är du säker på att du vill stänga instruktionsfilmen?',
                            showCancelButton: true,
                            confirmButtonText: 'Ja',
                            cancelButtonText: 'Nej',
                        }).then(result => {
                            if (result.value) {
                                sessionStorage.setItem(
                                    'disclaimershowed', true);
                                this.setState({
                                    alertOpen: false,
                                    filmOpen: false,
                                });
                            }
                        });
                    })()}
                    <h1>Introduktionsfilm</h1>
                    <Player>
                        <iframe
                            id="video-player"
                            title="how it works"
                            src="https://player.vimeo.com/video/378282964"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen
                        ></iframe>
                        <button onClick={() => this.handlePopUp()}>
                            Till besiktningen
                        </button>
                    </Player>
                </VideoWrapper>
            ) : (
                <>
                    <Div100vh>
                        <Wrapper>
                            <TopButtons
                                helplink="/help-inspection"
                                backgroundColor="#002837"
                                handleBack={() => this.handleBack()}
                                noQuestionMark={
                                    inspectionType === 'measure' &&
                                    showCheckList
                                }
                            >
                                <div style={{display: 'flex'}}>
                                    <MaterialIcon
                                        icon="location_city"
                                        size="28px"
                                        color="#e2edf5"
                                    />
                                    <span style={{marginLeft: '15px'}}>
                                    {`Lgh ${this.context.state.apistate.rental_object_appartment_number}`}{' '}
                                        {this.context.state.apistate.rental_object_address}
                                </span>
                                </div>
                            </TopButtons>

                            <div style={{width: '100%'}}>
                                {this.state.showDisclaimer ? (
                                    <Disclaimer
                                        style={{
                                            opacity: this.state.fadeDisclaimer
                                                ? '0'
                                                : '1',
                                        }}
                                    >
                                        <DisclaimerText>
                                            Klicka på det rum du vill börja
                                            besikta,
                                            när alla rum är
                                            besiktigade kan du skicka in för
                                            granskning av din
                                            förvaltare.
                                        </DisclaimerText>
                                        <div onClick={this.closeDisclaimer}>
                                            <MaterialIcon
                                                icon="clear"
                                                size="24px"
                                                color="#002837"
                                            />
                                        </div>
                                    </Disclaimer>
                                ) : null}

                                <div
                                    style={{
                                        width: '100%',
                                        margin: '36px 0 0',
                                    }}
                                >
                                    {this.renderRooms()}
                                </div>

                                {inspectionType === 'out' ? (
                                    showCheckList ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Divider />
                                            <Link
                                                to="/checklist"
                                                style={{width: '100%'}}
                                            >
                                                <RoomList
                                                    color="rgb(0, 40, 55)"
                                                    room="Checklista städning"
                                                    icon={
                                                        this.context.state.apistate.cleaning_inspection
                                                            .processed
                                                            ? 'check'
                                                            : 'keyboard_arrow_right'
                                                    }
                                                    backgroundColor={
                                                        this.context.state.apistate.cleaning_inspection
                                                            .processed
                                                            ? 'rgb(82, 178, 89)'
                                                            : '#186096'
                                                    }
                                                />
                                            </Link>
                                        </div>
                                    ) : null
                                ) : null}

                                {inspectionType === 'in' && showCheckList && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Divider />
                                        <Link
                                            onClick={() => this.handleSaveNoBack()}
                                            style={{width: '100%'}}
                                            to="/report-cleaning-flaw"
                                        >
                                            <RoomList
                                                room="Visa brist"
                                                icon={
                                                    this.checkForCleaningFlaws()
                                                        ? 'check'
                                                        : 'keyboard_arrow_right'
                                                }
                                                backgroundColor={
                                                    this.checkForCleaningFlaws()
                                                        ? 'rgb(82, 178, 89)'
                                                        : '#186096'
                                                }
                                                color="#002837"
                                            />
                                        </Link>
                                    </div>
                                )}

                                {
                                    showCheckList &&
                                    (inspectionType === 'out'
                                            ? cleaningInspectionProcessed
                                            : true
                                    ) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Divider />
                                            <CheckBoxContainer>
                                                <div style={{width: '231px'}}>
                                                    <p
                                                        style={{
                                                            fontSize: '16px',
                                                            color: '#002837',
                                                        }}
                                                    >
                                                        <strong>Jag intygar</strong>
                                                        {' '}att uppgifterna i
                                                        besiktningen överensstämmer
                                                        med
                                                        rådande förhållanden i
                                                        bostaden.
                                                    </p>
                                                </div>
                                                <label
                                                    className="switch"
                                                    htmlFor="checkbox"
                                                    onClick={e => this.onChecked(e)}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id="checkbox"
                                                        readOnly
                                                        checked={this.state.checked}
                                                    />
                                                    <div className="slider round"></div>
                                                </label>
                                            </CheckBoxContainer>
                                            <div
                                                onClick={() => this.handleSave()}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                <Buttons
                                                    disabled={
                                                        !this.state.checked ||
                                                        this.state.disabled === true
                                                    }
                                                    color="#51B35A"
                                                    textColor="#fff"
                                                    message="Slutför och skicka in"
                                                    style={{
                                                        width: 'calc(100% - 60px)',
                                                        marginBottom: '50px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </Wrapper>
                    </Div100vh>
                    {this.state.isSigningWithBankId && (
                        <LoaderContainer>
                            <Loader>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </Loader>
                            <h3>Väntar på svar från Mobilt BankID</h3>
                        </LoaderContainer>
                    )}
                </>
            )
        );
    }
}

Inspection.contextType = AppContext;

const Wrapper = styled.div`
    background: #e2edf5;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding-top: 90px;
`;

const Disclaimer = styled.div`
    display: flex;
    margin: 4px 8px 0;
    padding: 15px;
    background-color: white;
    color: #002837;
    position: relative;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all 0.2s;
`;

const DisclaimerText = styled.p`
    padding-right: 5px;
`;

const Overlay = styled.div`
    position: absolute;
    background: #808080;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    z-index: 1;
    border-radius: 3px;
`;

const Divider = styled.div`
    width: calc(100% - 60px);
    height: 1px;
    margin: 10px 0 20px;
    background-color: #002837;
    opacity: 0.3;
`;

const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin-bottom: 25px;

    .switch {
        display: inline-block;
        height: 30px;
        position: relative;
        width: 52px;
    }

    .switch input {
        display: none;
    }

    .slider {
        background-color: #ccc;
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
    }

    .slider:before {
        background-color: #fff;
        bottom: 0px;
        content: '';
        height: 28px;
        left: 0px;
        position: absolute;
        transition: 0.4s;
        width: 28px;
        border: 1px solid rgba(0, 40, 55, 0.51);
    }

    input:checked + .slider {
        background-color: #66bb6a;
    }

    input:checked + .slider:before {
        transform: translateX(22px);
    }

    .slider.round {
        border-radius: 3px;
    }

    .slider.round:before {
        border-radius: 3px;
    }
`;

const VideoWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #e2edf5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;
`;

const Player = styled.div`
    position: absolute;
    width: 100%;
    top: 50%;

    transform: translateY(-50%);

    text-align: center;

    iframe {
        width: 100vw;
        height: 31vh;
    }

    button {
        border: 0;
        background: transparent;
    }
`;

const lds_ellipsis1 = keyframes`
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`;
const lds_ellipsis2 = keyframes`
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
`;
const lds_ellipsis3 = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
`;

const LoaderContainer = styled.div`
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;

    h3 {
        color: #fff;
        font-weight: normal;
    }
`;

const Loader = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    z-index: 1000;

    div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    div:nth-child(1) {
        left: 8px;
        animation: ${lds_ellipsis1} 0.6s infinite;
    }

    div:nth-child(2) {
        left: 8px;
        animation: ${lds_ellipsis2} 0.6s infinite;
    }

    div:nth-child(3) {
        left: 32px;
        animation: ${lds_ellipsis2} 0.6s infinite;
    }

    div:nth-child(4) {
        left: 56px;
        animation: ${lds_ellipsis3} 0.6s infinite;
    }
`;
