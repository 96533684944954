import React, { Component } from 'react';
import styled from 'styled-components';
import QuestionMarkView from '../../components/QuestionMarkView';
import Div100vh from 'react-div-100vh';

export default class KitchenInspectionHelp extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <QuestionMarkView
          index={this.props.location.state.index}
          goBack={this.handleBack}
        >
          <Headline>Checklista vitvaror</Headline>
          <Text>
            Här behöver du inte ta bilder. Testa vitvaror och övrig
            köksutrustning. Kontrollera att spis, kyl och annan elektronik får
            ström samt är hela och rena. Du behöver inte kontrollera att
            plattorna blir varma, bara att något indikerar att plattan är
            påslagen. Upptäcker du fel på vitvaror, beskriv problemet så att
            hyresvärden snabbt kan åtgärda det.
          </Text>
        </QuestionMarkView>
      </Div100vh>
    );
  }
}

const Headline = styled.h1`
  color: rgb(0, 40, 55);
  text-align: center;
  margin-bottom: 15px;
`;

const Text = styled.p`
  color: rgb(0, 40, 55);
`;
