import React, { Component } from 'react';
import SettingsHeader from '../../components/SettingsHeader';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import Button from '../../components/Buttons';
import Div100vh from 'react-div-100vh';

export default class Contact extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  handleMail = email => {
    window.location.href = `mailto:${email}`;
  };

  render() {
    return (
      <Div100vh>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <SettingsHeader
            handleBack={this.handleBack}
            color="white"
            header={this.context.state.lang.Settings.Contact}
          />

          <Wrapper>
            <BtnWrp>
              <div style={{ padding: '30px 0' }}>
                <Text>
                  {this.context.state.lang.Settings.ContactPage.Preamble1}
                </Text>
                <Text>
                  {this.context.state.lang.Settings.ContactPage.Preamble2}
                </Text>
              </div>

              <ContactWrapper style={{ color: '#002837' }}>
                <h2 style={{ fontSize: '27px' }}>
                  {this.context.state.apistate.inspector_name}
                </h2>
                <p style={{ marginTop: '10px' }}>
                  {this.context.state.apistate.inspector_role}
                </p>
                <p>{this.context.state.apistate.company_name}</p>
              </ContactWrapper>

              <a href={`tel:${this.context.state.apistate.inspector_phone}`}>
                <Button
                  message={this.context.state.apistate.inspector_phone}
                  color="#186096"
                  textColor="white"
                />
              </a>

              <Button
                onClick={() =>
                  this.handleMail(
                    `${this.context.state.apistate.inspector_email}`
                  )
                }
                message="Email"
                color="#186096"
                textColor="white"
              />

              <Button
                onClick={this.handleBack}
                message={this.context.state.lang.InfoPage.Tab2.Button}
                color="#E2EDF5"
              />
            </BtnWrp>
          </Wrapper>
        </div>
      </Div100vh>
    );
  }
}

Contact.contextType = AppContext;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  color: white;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const Text = styled.p`
  color: #002837;
  width: 100%;
  text-align: center;
`;

const ContactWrapper = styled.div`
  padding: 15px 0 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BtnWrp = styled.div`
  width: 75%;
  margin-bottom: 25px;
`;
