import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../context/Context';
export default class Buttons extends React.Component {
  handleBack = () => {
    this.props.handleBack();
  };

  render() {
    return (
      <ButtonWrapper backgroundColor={this.props.backgroundColor}>
        <Button onClick={this.handleBack}>
          <Icon
            className="material-icons"
            style={{ fontSize: '22px', color: 'white' }}
          >
            keyboard_arrow_left
          </Icon>
        </Button>
        {this.props.children}

        <div style={{ display: 'flex' }}>
          <Button onClick={this.props.handleSaveNoBack}>
            {this.props.noQuestionMark ? null : (
              <Link
                to={{
                  pathname: this.props.helplink,
                  state: {
                    index: this.props.index
                  }
                }}
                style={{ display: 'flex' }}
              >
                <Icon className="material-icons" style={{ fontSize: '22px' }}>
                  help_outline
                </Icon>
              </Link>
            )}
          </Button>
        </div>
      </ButtonWrapper>
    );
  }
}

Buttons.contextType = AppContext;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  background-color: ${props => props.backgroundColor};
`;

const Button = styled.div`
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;
