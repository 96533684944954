import React, { Component } from 'react';
import styled from 'styled-components';
import QuestionMarkView from '../../components/QuestionMarkView';
import { AppContext } from '../../context/Context';
import Div100vh from 'react-div-100vh';

export default class StartPageHelp extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <QuestionMarkView goBack={this.handleBack}>
          <Player>
            <iframe
              title="how it works"
              src="https://player.vimeo.com/video/378282964"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </Player>
          <Text>
            {this.context.state.apistate.inspection_type !== 'measure'
              ? 'När du trycker på “Starta besiktningen” följer en guide för hur du med hjälp av bilder dokumenterar skicket på din lägenhet. Du kan när som helst trycka på ett frågetecken för att få hjälp eller tips. Du måste ha el i lägenheten för att kunna genomföra alla steg. I slutet av processen krävs bank-id. Så mycket som möjligt ska synas på bild i besiktningen. Alla ytskikt måste visas: Väggar, golv, tak, fönster och så vidare. När du är klar ser hyresvärden resultatet i sin dator. Om någon bild är otydlig, kan du behöva komplettera. I så fall får du ett sms med instruktioner. Inflyttande hyresgäst gör samma besiktning som du. Tänk därför på att allt måste synas på bild – även sådant som är mindre bra. Det är viktigt att du intygar att du har städat. Om den nya hyresgäster anmärker på något, kan du tvingas bekosta omstädning. Anlitar du en städfirma är skicket fortfarande ditt ansvar, varför det kan vara klokt att besiktiga samtidigt som du kontrollerar städningen.'
              : 'När du trycker ”starta besiktningen” kommer du följa en guide. Syftet är att dokumentera vad du vill ha åtgärdat. Om du råkar på problem i något steg så kan du alltid trycka på frågetecknet så får du upp lite tips om hur du ska göra i just det momentet för att få till ett bra resultat. Du behöver Bank-ID för att kunna slutföra besiktningen. När du gjort besiktningen får din hyresvärd upp bilder och texter i sin dator. Om någon eller några bilder blivit för otydliga så kanske du behöver komplettera med nya bilder. I så fall får du ett sms med instruktioner. Mer information finns på www.minbesiktning.se'}
          </Text>
        </QuestionMarkView>
      </Div100vh>
    );
  }
}

StartPageHelp.contextType = AppContext;

const Text = styled.p`
  color: rgb(0, 40, 55);
  padding: 0 30px;
`;

const Player = styled.div`
  position: relative;
  width: 100%;
  height: 31vh;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
