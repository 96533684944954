import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';

export default class Buttons extends React.Component {
  render() {
    return (
      <Button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        isSmall={this.props.isSmall}
        color={this.props.color}
        textColor={this.props.textColor}
        style={this.props.style}
      >
        {this.props.message}
      </Button>
    );
  }
}

Buttons.contextType = AppContext;

const Button = styled.button`
  background-color: ${props => props.color};
  color: ${props => props.textColor};
  height: ${props => (props.isSmall ? '45px' : '60px')};
  width: 100%;
  border: none;
  outline: none;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 18px;
  transition: all 0.4s;

  &:disabled {
    opacity: 0.5;
  }
`;
