import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';

const Spacer = styled.div`
  margin-bottom: 15px;
`;

export default class TextCarousel extends React.Component {
  state = {
    textIdx: 0
  };

  componentDidMount() {
    this.timeout = setInterval(() => {
      this.setState({
        ...this.state,
        textIdx: this.state.textIdx === 0 ? 1 : 0
      });
    }, 3000);
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    return (
      <Carousel>
        {this.state.textIdx === 0 ? (
          <Spacer>{this.context.state.lang.StartPage.CarouselOne}</Spacer>
        ) : (
          <div>
            <Spacer>{this.context.state.lang.StartPage.CarouselTwo1}</Spacer>
            <Spacer>{this.context.state.lang.StartPage.CarouselTwo2}</Spacer>
            <Spacer>{this.context.state.lang.StartPage.CarouselTwo3}</Spacer>
          </div>
        )}
      </Carousel>
    );
  }
}

TextCarousel.contextType = AppContext;

const Carousel = styled.h2`
  font-weight: 200;
  text-align: center;
  font-size: 26px;
  line-height: 32px;
  height: 125px;
  width: 60%;

  @keyframes fade {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: fade;
  animation-duration: 3000ms;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
`;
