import React, {Component} from 'react';
import styled from 'styled-components';
import {AppContext} from '../context/Context';
import Button from '../components/Buttons';
import MaterialIcon from '../components/MaterialIcon';
import {Link} from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import RoomList from '../components/RoomList';

export default class ReportCleaningFlaw extends Component {
    //{id: 10, image_type: "normal", image: "0-photo-1523755231516-e43fd2e8dca5.jpg", image_url: "https://min-besiktning-api.tbnetcore02.tankbar.com…tion_12308/0-photo-1523755231516-e43fd2e8dca5.jpg", comment: null}

    state = {
        inputs: [1],
        images: [],
        comment: '',
        imagesFromApi: [],
        showImage: false,
        activeImage: '',
        show: false,
        currentImage: 0,
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    listFlaws = () => {
        let flaws = [];

        this.context.state.apistate.inspection_data[
            this.props.location.state.index
            ].images.forEach(image => {
            if (image.image_type === 'flaw') {
                for (let i = 0; i < image.index_group + 1; i++) {
                    if (i >= flaws.length) {
                        flaws.push(i);
                    }
                }
            }
        });

        return flaws;
    };

    render() {
        return (
            <Div100vh>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100%',
                    }}
                >
                    <ReportFlawHeader>
                        <MaterialIcon icon="report" size="28px" color="#fff" />
                        <span>Visa brist</span>
                        <MaterialIcon
                            icon="keyboard_arrow_up"
                            size="28px"
                            color="#fff"
                        />
                    </ReportFlawHeader>

                    <Wrapper>
                        <h1
                            style={{
                                width: '100%',
                                marginTop: '30px',
                                marginBottom: '30px',
                                textAlign: 'center',
                            }}
                        >
                            <Text>Rapportera brister</Text>
                        </h1>

                        <div style={{width: '100%', marginTop: '36px'}}>
                            {this.listFlaws().map((item, index) => {
                                return (
                                    <Link
                                        to={{
                                            pathname: '/show-flaw',
                                            state: {
                                                image_group: index,
                                                index: index,
                                                roomName: item.room_name,
                                                roomIndex: this.props.location.state.index,
                                            },
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        <RoomList
                                            color="rgb(0, 40, 55)"
                                            room={`Brist ${index + 1}`}
                                            icon="check"
                                            backgroundColor="rgb(82, 178, 89)"
                                        />
                                    </Link>
                                );
                            })}

                            <Link
                                to={{
                                    pathname: '/report-flaw-room',
                                    state: {
                                        index: this.props.location.state.index,
                                        room: this.props.location.state.room,
                                        index_group: this.listFlaws().length,
                                    },
                                }}
                                style={{width: '100%'}}
                            >
                                <RoomList
                                    color="rgb(0, 40, 55)"
                                    room="Lägg till brist"
                                    icon="add"
                                    backgroundColor="#002837"
                                />
                            </Link>
                        </div>

                        <BtnWrp>
                            <Button
                                style={{marginRight: '7.5px'}}
                                onClick={this.handleBack}
                                message={this.context.state.lang.InfoPage.Tab2.Button}
                                color="#fff"
                            />
                            <Button
                                style={{marginLeft: '7.5px', color: 'white'}}
                                onClick={this.handleBack}
                                message="Spara"
                                color="#51B35A"
                            />
                        </BtnWrp>
                    </Wrapper>
                </div>
            </Div100vh>
        );
    }
}

ReportCleaningFlaw.contextType = AppContext;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: #e2edf5;
    color: white;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    .intro-text {
        text-align: center;
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }
`;

const Text = styled.p`
    color: #002837;
    width: 100%;
    padding: 0 30px;
`;

const BtnWrp = styled.div`
    width: 75%;
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    bottom: 0%;
`;

const ReportFlawHeader = styled.div`
    width: 100vw;
    padding: 50px 0 20px;
    background: #d46369;
    display: flex;
    justify-content: center;

    div {
        display: flex;
    }

    span {
        color: #ffffff;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        margin: 0 15px;
        color: fff;
    }
`;
