import React from 'react';
import styled from 'styled-components';

function TopNavigation({background, onClickBack, onClickHelp, children}) {
    return (
        <Wrapper background={background}>
            {onClickBack && (
                <Button onClick={onClickBack}>
                    <Icon
                        className="material-icons"
                        style={{fontSize: '22px', color: 'white'}}
                    >
                        keyboard_arrow_left
                    </Icon>
                </Button>
            )}

            {children}

            {onClickHelp && (
                <div style={{display: 'flex'}}>
                    <Button onClick={onClickHelp}>
                        <Icon
                            className="material-icons"
                            style={{fontSize: '22px'}}
                        >
                            help_outline
                        </Icon>
                    </Button>
                </div>
            )}
        </Wrapper>
    );
}

export {TopNavigation};

const Icon = styled.i`
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
`;

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15px;
    background-color: ${props => props.background};
`;

const Button = styled.div`
    height: 40px;
    width: 40px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: white;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
`;
