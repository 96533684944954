import React, { Component } from 'react';
import SettingsHeader from '../../components/SettingsHeader';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import Button from '../../components/Buttons';
import Div100vh from 'react-div-100vh';

export default class About extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <SettingsHeader
            handleBack={this.handleBack}
            color="white"
            header={this.context.state.lang.Settings.About}
          />

          <Wrapper>
            <strong style={{ width: '100%', marginTop: '30px' }}>
              <Text>{this.context.state.lang.Settings.AboutPage.Headline}</Text>
            </strong>

            <Text style={{ marginBottom: '50px' }}>
              {this.context.state.lang.Settings.AboutPage.Text}
            </Text>

            <BtnWrp>
              <Button
                onClick={this.handleBack}
                message={this.context.state.lang.InfoPage.Tab2.Button}
                color="#E2EDF5"
              >
                Tillbaka
              </Button>
            </BtnWrp>
          </Wrapper>
        </div>
      </Div100vh>
    );
  }
}

About.contextType = AppContext;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  color: white;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const Text = styled.p`
  color: #002837;
  width: 100%;
  padding: 0 30px;
`;

const BtnWrp = styled.div`
  width: 75%;
  margin-bottom: 25px;
`;
