import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../context/Context';

export default class Buttons extends React.Component {
  render() {
    return (
      <ButtonWrapper backgroundColor={this.props.backgroundColor}>
        <div style={{ display: 'flex' }}>
          {this.props.questionmarkremove ? null : (
            <Button>
              <Link to={this.props.helplink} style={{ display: 'flex' }}>
                <Icon className="material-icons" style={{ fontSize: '22px' }}>
                  help_outline
                </Icon>
              </Link>
            </Button>
          )}

          {this.context.state.isLoggedIn ? (
            <Button>
              <Link to="/settings" style={{ display: 'flex' }}>
                <Icon className="material-icons" style={{ fontSize: '24px' }}>
                  person_outline
                </Icon>
              </Link>
            </Button>
          ) : null}
        </div>

        {this.props.children}

        <Button onClick={this.context.toggleLang}>
          {this.context.state.buttonContent}
        </Button>
      </ButtonWrapper>
    );
  }
}

Buttons.contextType = AppContext;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  background-color: ${props => props.backgroundColor};
`;

const Button = styled.div`
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
`;
