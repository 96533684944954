import React, {Component} from 'react';
import styled from 'styled-components';
import {AppContext} from '../context/Context';
import Button from '../components/Buttons';
import MaterialIcon from '../components/MaterialIcon';
//import Div100vh from 'react-div-100vh';
import SweetAlert from 'sweetalert2-react';
import RoomList from '../components/RoomList';
import Camera from '../components/Camera';
import axios from 'axios';

export default class ReportCleaningFlawRoom extends Component {
    //{id: 10, image_type: "normal", image: "0-photo-1523755231516-e43fd2e8dca5.jpg", image_url: "https://min-besiktning-api.tbnetcore02.tankbar.com…tion_12308/0-photo-1523755231516-e43fd2e8dca5.jpg", comment: null}

    state = {
        inputs: [1, 2, 3],
        images: [],
        comment: '',
        imagesFromApi: [],
        showImage: false,
        activeImage: '',
        show: false,
        currentImage: 0,
        selected: this.context.state.apistate.inspection_data[0].room_name,
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    handleChange = e => {
        this.setState({
            comment: e.target.value,
        });
    };

    cameraInput = () => {
        return this.state.inputs.map((key, index) => {
            if (index === this.state.currentImage) {
                return (
                    <Camera
                        background="white"
                        cameraColor="#186096"
                        text={'Ta bild'}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                    />
                );
            } else if (index < this.state.currentImage) {
                return (
                    <Camera
                        background="white"
                        cameraColor="#186096"
                        text={'Bild ' + (index + 1)}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                    />
                );
            } else if (index > this.state.currentImage) {
                return (
                    <Camera
                        background="transparent"
                        visible
                        cameraColor="#002837"
                        text={'Bild ' + (index + 1)}
                        key={index}
                        handleImage={(image, index, apiImage) =>
                            this.handleImage(image, index, apiImage)
                        }
                        showImage={this.showImage}
                        index={index}
                        imageFromApi={this.state.imagesFromApi[index]}
                        disabled
                    />
                );
            }
            return null;
        });
    };

    addImage() {
        this.setState({
            inputs: [...this.state.inputs, this.state.inputs.length + 1],
        });
    }

    handleSelect = e => {
        e.preventDefault();

        this.setState({
            selected: e.target.value,
        });
    };

    handleImage = (image, index, apiImage) => {
        if (apiImage) {
            apiImage.image_url = null;
            apiImage.image = image;
        } else {
            apiImage = {
                image: image,
                name: image.name,
                id: null,
                image_type: 'cleaning',
                comment: `${this.state.comment}`,
                status: 0,
            };
        }

        let images = [...this.state.images];
        images.splice(index, 1, apiImage);

        this.setState({
            images: images,
            currentImage: this.state.currentImage + 1,
        });
    };

    showImage = image => {
        this.setState({
            showImage: true,
            activeImage: image,
        });
    };

    getSelectedRoom = () => {
        for (const room of this.context.state.apistate.inspection_data) {
            let currentRoom = JSON.parse(JSON.stringify(room));
            if (currentRoom.room_name === this.state.selected) {
                return currentRoom;
            }
        }
    };

    handleSave = () => {
        this.setState({
            disabled: true,
        });

        const initialState = JSON.parse(
            JSON.stringify(this.context.state.apistate),
        );

        const roomInfo = this.getSelectedRoom();

        initialState.inspection_data = [roomInfo];

        const apiImages = JSON.parse(
            JSON.stringify(initialState.inspection_data[0].images),
        );

        const images = this.state.images;

        const formData = new FormData();

        for (const [i, imageObj] of images.entries()) {
            Object.defineProperty(imageObj.image, 'name', {
                value: imageObj.image.name,
                writable: true,
            });
            let imageIsNew = true;
            let match = false;

            const now = Date.now();

            if (apiImages.length > 0) {
                for (const globalStateImage of apiImages) {
                    if (
                        globalStateImage.id === imageObj.id &&
                        globalStateImage.id !== null
                    ) {
                        globalStateImage.image =
                            `${i}-${now}-cleaning_flaw-${imageObj.image.name.replace(
                                /\s/g,
                                '',
                            )}`;
                        globalStateImage.comment = '';
                        globalStateImage.image_type = 'cleaning';
                        globalStateImage.image_url = null;

                        imageObj.image.name =
                            `${i}-${now}-cleaning_flaw-${imageObj.image.name.replace(
                                /\s/g,
                                '',
                            )}`;
                        formData.append(
                            'files',
                            imageObj.image,
                            `${imageObj.image.name.replace(/\s/g, '')}`,
                        );

                        imageIsNew = false;
                        match = true;
                    }
                }

                if (match === false) {
                    const newImage = {
                        id: null,
                        url: null,
                        image: `${i}-${now}-cleaning_flaw-${imageObj.image.name.replace(
                            /\s/g,
                            '',
                        )}`,
                        image_type: 'cleaning',
                        comment: this.state.comment,
                        status: 0,
                        index_group: 0,
                    };

                    apiImages.push(newImage);

                    imageObj.image.name =
                        `${i}-${now}-cleaning_flaw-${imageObj.image.name.replace(
                            /\s/g,
                            '',
                        )}`;
                    formData.append(
                        'files',
                        imageObj.image,
                        `${imageObj.image.name.replace(/\s/g, '')}`,
                    );

                    imageIsNew = false;
                }
            }

            if (imageIsNew === true) {
                const newImage = {
                    id: null,
                    url: null,
                    image: `${i}-${now}-cleaning_flaw-${imageObj.image.name.replace(
                        /\s/g,
                        '',
                    )}`,
                    image_type: 'cleaning',
                    comment: this.state.comment,
                    status: 0,
                    index_group: 0,
                };

                apiImages.push(newImage);

                imageObj.image.name =
                    `${i}-${now}-cleaning_flaw-${imageObj.image.name.replace(
                        /\s/g,
                        '',
                    )}`;
                formData.append(
                    'files',
                    imageObj.image,
                    `${imageObj.image.name.replace(/\s/g, '')}`,
                );
            }
        }

        initialState.inspection_data[0].images = apiImages;

        formData.append('data', JSON.stringify(initialState));

        const options = {
            url: `${process.env.REACT_APP_API_URL}/app/createInspectionDataRoom`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        };

        axios(options).then(async response => {
            this.context.setApiState(response.data);
            this.setState({
                show: true,
            });
            setTimeout(() => {
                this.setState({
                    show: false,
                });
            }, 1250);
            setTimeout(() => {
                this.handleBack();
            }, 1750);
        });
    };

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    backgroundColor: '#e2edf5',
                }}
            >
                <ReportFlawHeader>
                    <MaterialIcon icon="report" size="28px" color="#fff" />
                    <span>Visa brist</span>
                    <MaterialIcon
                        icon="keyboard_arrow_up"
                        size="28px"
                        color="#fff"
                    />
                </ReportFlawHeader>

                <Wrapper>
                    <h1
                        style={{
                            width: '100%',
                            marginTop: '30px',
                            marginBottom: '30px',
                            textAlign: 'center',
                        }}
                    >
                        <Text>Lägg till brist</Text>
                    </h1>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            padding: '0 30px',
                            marginBottom: '10px',
                        }}
                    >
                        <Select
                            disabled={this.state.images.length > 0}
                            onChange={e => this.handleSelect(e)}
                        >
                            {this.context.state.apistate.inspection_data.map(
                                room => {
                                    return <option>{room.room_name}</option>;
                                })}
                        </Select>
                        <MaterialIcon
                            style={{
                                position: 'absolute',
                                right: '40px',
                                top: '6px',
                            }}
                            icon="keyboard_arrow_down"
                            size="28px"
                            color="rgba(0,40,55,0.7)"
                        />
                    </div>
                    <div style={{width: '100%'}}>
                        {this.cameraInput()}
                        {this.state.inputs.length < 10 ? (
                            <i
                                style={{width: '100%'}}
                                onClick={() => this.addImage()}
                            >
                                <RoomList
                                    color="rgb(0, 40, 55)"
                                    room="Lägg till bild"
                                    icon="add"
                                    backgroundColor="#002837"
                                />
                            </i>
                        ) : null}
                    </div>

                    <TextArea
                        placeholder="Du måste skriva en kommentar"
                        onChange={e => this.handleChange(e)}
                        value={this.state.comment}
                    />
                    {this.state.showImage ? (
                        <ImageWrapper>
                            <div style={{position: 'relative'}}>
                                <Close
                                    onClick={() => {
                                        this.setState({
                                            showImage: false,
                                            activeImage: '',
                                        });
                                    }}
                                >
                                    <MaterialIcon
                                        icon="close"
                                        size="26px"
                                        color="rgba(0, 40, 55, 1)"
                                    />
                                </Close>
                                <FullImage src={this.state.activeImage}></FullImage>
                            </div>
                        </ImageWrapper>
                    ) : null}
                    <SweetAlert
                        show={this.state.show}
                        type="success"
                        title="Bristen sparad"
                        onConfirm={() => this.setState({show: false})}
                        showConfirmButton={false}
                    />
                    <BtnWrp>
                        <Button
                            style={{marginRight: '7.5px'}}
                            onClick={this.handleBack}
                            message={this.context.state.lang.InfoPage.Tab2.Button}
                            color="#fff"
                        />
                        <Button
                            disabled={
                                this.state.disabled ||
                                this.state.images < 1 ||
                                this.state.comment === ''
                            }
                            style={{marginLeft: '7.5px', color: 'white'}}
                            onClick={() => this.handleSave()}
                            message="Spara"
                            color="#51B35A"
                        />
                    </BtnWrp>
                </Wrapper>
            </div>
        );
    }
}

ReportCleaningFlawRoom.contextType = AppContext;

const Wrapper = styled.div`
    display: flex;
    background-color: #e2edf5;
    color: white;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    .intro-text {
        text-align: center;
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }
`;

const Text = styled.p`
    color: #002837;
    width: 100%;
    padding: 0 30px;
`;

const BtnWrp = styled.div`
    width: 75%;
    margin-bottom: 25px;
    display: flex;
    position: relative;
    bottom: 0%;
    margin-top: 36px;
`;

const ReportFlawHeader = styled.div`
    width: 100vw;
    padding: 50px 0 20px;
    background: #d46369;
    display: flex;
    justify-content: center;

    div {
        display: flex;
    }

    span {
        color: #ffffff;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        display: inline-block;
        margin: 0 15px;
        color: fff;
    }
`;

const Select = styled.select`
    width: 100%;
    padding: 10px 10px 10px 20px;
    border: 0;
    border-radius: 3px;
    color: rgba(0, 40, 55, 0.7);
    font-size: 18px;
    line-height: 18px;
    background: white;
    -webkit-appearance: none;

    option {
        border: 0;
        outline: 0;
        border-radius: 3px;
        color: #002837;
        font-size: 18px;
        line-height: 18px;
    }
`;

const ImageWrapper = styled.div`
    position: absolute;
    background: #e2edf5;
    color: #fff;
    height: calc(100% - 90px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

const FullImage = styled.img`
    width: 100%;
`;

const Close = styled.div`
    height: 24px;
    width: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
`;

const TextArea = styled.textarea`
    height: 137px;
    width: calc(100% - 60px);
    resize: none;
    border: 0;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 20px;
    color: #636363;
`;
