import React, { Component } from 'react';
import SettingsHeader from '../../components/SettingsHeader';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import Button from '../../components/Buttons';
import MaterialIcon from '../../components/MaterialIcon';
import Div100vh from 'react-div-100vh';

export default class User extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <SettingsHeader
            handleBack={this.handleBack}
            color="white"
            header={this.context.state.lang.Settings.UserInfo}
          />

          <Wrapper>
            <Option style={{ marginTop: '7px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ opacity: '.7', marginBottom: '12px' }}>
                  Alexander Nyström
                </span>
                <span style={{ opacity: '.7' }}>
                  alexander.nystrom@tankbar.com
                </span>
              </div>
            </Option>

            <Option>
              <span>0705-10 20 30</span>
              <MaterialIcon
                icon="create"
                size="22px"
                color="rgb(0, 40, 55)"
                style={{ opacity: '.7' }}
              />
            </Option>

            <Option>
              <span>Lösenord</span>
              <MaterialIcon
                icon="create"
                size="24px"
                color="rgb(0, 40, 55)"
                style={{ opacity: '.7' }}
              />
            </Option>

            <BtnWrp>
              <Button
                onClick={() => this.handleBack()}
                message="Uppdatera"
                color="#52b259"
                textColor="white"
              />
            </BtnWrp>
          </Wrapper>
        </div>
      </Div100vh>
    );
  }
}

User.contextType = AppContext;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  color: white;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const Option = styled.div`
  width: 75%;
  color: #002837;
  border-bottom: 0.5px solid lightgray;
  padding: 18px 25px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const BtnWrp = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  width: 75%;
`;
