import React, { Component } from 'react';
import SettingsHeader from '../../components/SettingsHeader';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import Button from '../../components/Buttons';
import Div100vh from 'react-div-100vh';

export default class Language extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  handleClick = selectedLang => {
    this.context.changeLang(selectedLang);
  };

  render() {
    return (
      <Div100vh>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <SettingsHeader
            handleBack={this.handleBack}
            color="white"
            header={this.context.state.lang.Settings.Language}
          />

          <Wrapper>
            <Headline>
              {this.context.state.lang.ChooseLanguage.ChooseLanguage}
            </Headline>

            <Option onClick={() => this.handleClick('sv')}>
              <span>Svenska</span>
              <Label htmlFor="radio-1" />
              <div>
                <Radio type="radio" name="radio" id="radio-1"></Radio>
              </div>
            </Option>

            <Option onClick={() => this.handleClick('en')}>
              <span>English</span>
              <Label htmlFor="radio-2" />
              <div>
                <Radio type="radio" name="radio" id="radio-2"></Radio>
              </div>
            </Option>

            <Option onClick={() => this.handleClick('ara')}>
              <span>(Arabiska) العربية</span>
              <Label htmlFor="radio-3" />
              <div>
                <Radio type="radio" name="radio" id="radio-3"></Radio>
              </div>
            </Option>

            <Option
              onClick={() => this.handleClick('fas')}
              style={{ borderBottom: 'none' }}
            >
              <span>(Persiska) فارسی</span>
              <Label htmlFor="radio-4" />
              <div>
                <Radio type="radio" name="radio" id="radio-4"></Radio>
              </div>
            </Option>

            <BtnWrp>
              <Button
                onClick={() => this.handleBack()}
                message={
                  this.context.state.lang.Settings.LanguagePage.UpdateButton
                }
                color="#52b259"
                textColor="white"
              />
            </BtnWrp>
          </Wrapper>
        </div>
      </Div100vh>
    );
  }
}

Language.contextType = AppContext;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  color: white;
  flex-direction: column;
  align-items: center;
  transition: bottom 0.2s, opacity 0.4s;
  flex-grow: 1;
`;

const Headline = styled.div`
  width: 100%;
  padding: 5px 0;
  text-align: center;
  background-color: #e2edf5;
  color: #002837;
`;

const Option = styled.div`
  width: 75%;
  text-align: left;
  color: #002837;
  border-bottom: 0.5px solid lightgray;
  padding: 25px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Radio = styled.input`
  height: 100%;
  width: 18px;
`;

const BtnWrp = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  width: 75%;
`;
