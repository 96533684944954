import React, { Component } from 'react';
import styled from 'styled-components';
import QuestionMarkView from '../../components/QuestionMarkView';
import { AppContext } from '../../context/Context';
import Div100vh from 'react-div-100vh';

export default class InspectionHelp extends Component {
  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Div100vh>
        <QuestionMarkView
          index={this.props.location.state.index}
          goBack={this.handleBack}
        >
          <Player>
            <iframe
              title="how it works"
              src="https://player.vimeo.com/video/378976221"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </Player>
          <Text>
            {this.context.state.apistate.inspection_type !== 'measure'
              ? 'Stäng dörrar och skåp när du tar dina bilder. Har du ett fönster med i bild och det är ljust ute? Tryck med ett finger på en mörk yta i det utsnitt som kameran ska fotografera. Då bör ljuset bli bättre.'
              : 'Ta bilder på det du vill ha åtgärdat. Försök att hålla kamera så att lika mycket av golvet som taket blir med i bild. Om du har ett fönster med i bild och det är ljust ute, testa då att trycka med finger någonstans i bilden på någon yta som är för mörk. Då borde kameran ställa om ljuset så att det blir bättre. Du kanske behöver komplettera med någon närbild.'}
          </Text>
        </QuestionMarkView>
      </Div100vh>
    );
  }
}

InspectionHelp.contextType = AppContext;

const Text = styled.p`
  color: rgb(0, 40, 55);
  padding: 0 30px;
`;

const Player = styled.div`
  position: relative;
  width: 100%;
  height: 31vh;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;
