import React, { Component } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Button from './Buttons';

import '../assets/tabs.css';
export default class InfoSwitch extends Component {
  render() {
    return (
      <Tabs
        style={{ backgroundColor: 'white', minHeight: 'calc(100vh - 75px)' }}
      >
        <TabList>
          <Tab style={{ color: '#002837' }}>
            <span>{this.context.state.lang.InfoPage.Tab1.TabTitle}</span>
          </Tab>
          <Tab>
            <span>{this.context.state.lang.InfoPage.Tab2.TabTitle}</span>
          </Tab>
        </TabList>

        <TabPanel>
          <Wrapper>
            <Headline>
              {this.context.state.lang.InfoPage.Tab1.Headline}
            </Headline>

            <Paragraph>
              <strong>{this.context.state.lang.InfoPage.Tab1.Preamble}</strong>
              <br />
              {this.context.state.lang.InfoPage.Tab1.Text}
            </Paragraph>

            <VideoWrp>
              <Iframe
                src=""
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              />
              <PlayButton>
                <Icon>play_circle_filled</Icon>
              </PlayButton>
            </VideoWrp>

            <BtnWrp>
              <Button
                onClick={this.props.handleBack}
                message={this.context.state.lang.InfoPage.Tab1.Button}
                color="#E2EDF5"
              />
            </BtnWrp>
          </Wrapper>
        </TabPanel>

        <TabPanel>
          <Wrapper>
            <Headline>
              {this.context.state.lang.InfoPage.Tab2.Headline}
            </Headline>

            <Paragraph>
              <strong>{this.context.state.lang.InfoPage.Tab2.Preamble}</strong>
              <br />
              {this.context.state.lang.InfoPage.Tab2.Text}
            </Paragraph>

            <VideoWrp>
              <Iframe
                src=""
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              />
              <PlayButton>
                <Icon>play_circle_filled</Icon>
              </PlayButton>
            </VideoWrp>

            <BtnWrp>
              <Button
                onClick={this.props.handleBack}
                message={this.context.state.lang.InfoPage.Tab2.Button}
                color="#E2EDF5"
                textColor="#002837"
              />
            </BtnWrp>
          </Wrapper>
        </TabPanel>
      </Tabs>
    );
  }
}

InfoSwitch.contextType = AppContext;

const Wrapper = styled.div``;

const Headline = styled.h1`
  padding: 0 30px;
  margin-top: 35px;
  font-size: 28px;
  font-weight: 600;
  color: #002837;
`;

const Paragraph = styled.div`
  padding: 0 30px;
  margin-top: 10px;
  line-height: 27px;
  color: #002837;
`;

const VideoWrp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Iframe = styled.iframe`
  margin-top: 30px;
  height: 205px;
  width: 100%;
`;

const PlayButton = styled.div`
  height: 60px;
  width: 60px;
  position: absolute;
`;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 72px;
  line-height: 1;
  color: white;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
`;

const BtnWrp = styled.div`
  padding: 50px;
`;
